import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const LinkModal = ({ isOpen, onClose, onCopy }) => {
    
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Link Copied
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" id="alert-dialog-description">
                    Your link has been copied to the clipboard.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCopy} color="primary">
                    OK
                </Button>
                <Button onClick={onClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LinkModal;


//export default LinkModal