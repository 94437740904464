import React, { useState } from 'react';
import PasswordResetModal from './PasswordResetModal';
import UnlockPassword from './UnlockPassword';

const ForgetPassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenUnlock, setIsOpenUnlock] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openUnlockModal = () => {
    setIsOpenUnlock(true);
  };

  const closeUnlockModal = () => {
    setIsOpenUnlock(false);
  };

  return (
    <>
      <div className="flex items-center justify-between" style={{ marginTop: "10px" }}>
        <div>
          <button
            onClick={openModal}
            style={{ color: '#428bca' }}
            className="font-medium hover:text-[#428bca] focus:outline-none"
          >
            Forgot your password?
          </button>
        </div>
        <div>
          <button
            onClick={openUnlockModal}
            style={{ color: '#428bca' }}
            className="font-medium hover:text-[#428bca] focus:outline-none"
          >
            Unlock your password?
          </button>
        </div>

      </div>
      <PasswordResetModal isOpen={isModalOpen} onClose={closeModal} />
      <UnlockPassword isOpen={isOpenUnlock} onClose={closeUnlockModal} />
    </>
  );
};

export default ForgetPassword;
