import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ThreeAccordions() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontSize: "1.8rem", fontWeight: "600" }}>
            Concept to Littera Learning Platform
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "1.5rem", fontWeight: "500"}}>
            Based on our decade long expertise and using concept of Knowledge
            engineering, we have developed Littera Learning Platform so persons
            can acquire new competency. Littera Learning collection, include
            learning course, learning profile and learning resources. In this
            learning process participant will acquire new competency with
            outcome as knowledge, skill and abilities. Littera comes with
            performance indicators in reference to complexity, scope, social and
            psychomotor.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{ fontSize: "1.8rem", fontWeight: "600" }}>
            Our Mission
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "1.5rem", fontWeight: "500" }}>
            There are three commitments we've made to the world. We've been
            grounded by these since day one: <br/>• Increase access to high-quality
            education for everyone, everywhere <br/>• Enhance teaching and learning
            on campus and online <br/>• Advance teaching and learning through
            research
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        sx={{ marginTop: "10px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography style={{ fontSize: "1.8rem", fontWeight: "600" }}>
            Our Vision
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ fontSize: "1.5rem", fontWeight: "500" }}>
            We are building and promoting a software platform that brings
            together the best people from all around the world to create courses
            for everyone, everywhere in the world
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
