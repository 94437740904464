import { useState, useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { Link as ScrollLink, Element } from "react-scroll";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import Laungauge from "../othcomponents/Laungauge";
import Header from "../header/Header";
import EventSection from "../home/EventSection";
import AboutUs from "../home/AboutUs";
import Achivment from "../home/Achivement";
import Contact from "../home/Contact";
import Footer from "../footer/Footer";
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const navigation = [
  { name: "HOME", to: "home", current: true },
  { name: "ABOUT US", to: "about", current: false },
  { name: "EVENTS", to: "event-section", current: false },
  { name: "ACHIVMENT", to: "achivment", current: false },
  { name: "CONTACT", to: "contact", current: false },
  { name: "POLICIES", to: "policy-page", current: false },
];

const navigationcalender = [
  { name: "Training calendar", href: "#", current: true },
  { name: "Participant Registration", href: "#", current: false },
  { name: "Instructor Registration", href: "#", current: false },
  { name: "Login", href: "#", current: false },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavbarHome() {
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false); // State for right panel
  const { translations } = useContext(TranslationContext);
  return (
    <>
      <div className="marquee">
        <div className="marquee-content" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="logo">
            <img src={require("../../assest/default_logo.png")} alt="Logo" style={{ width: "30px", marginRight: "10px" }} />
          </div>
          <p>Littera from where Knowledge sprouts</p>
        </div>
      </div>

      <Disclosure
        as="nav"
        className="bg-gray-800 fixed w-full z-40 top-4"
        style={{ padding: "1rem", marginTop: "32px" }}
      >
        {({ open }) => (
          <>
            <div
              className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8"
              style={{ maxWidth: "100rem" }}
            >
              <div className="relative flex h-14 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={require("../../assest/logo_pra1.png")}
                      alt="Your Company"
                    />
                  </div>

                  <div className="hidden11 sm:ml-6 sm:block  navbarclass">
                    <div className="flex space-x-4 ">
                      {navigation.map((item) => (
                        <ScrollLink
                          key={item.name}
                          to={item.to}
                          spy={true}
                          smooth={true}
                          duration={500}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          activeClass="active"
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </ScrollLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <NavLink to="/login-page">
                    <button
                      type="button"
                      className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      style={{ marginTop: "0px" }}
                    >
                      Login
                    </button>
                  </NavLink>

                  <Menu as="div" className="relative ml-3">
                    <Laungauge />
                  </Menu>

                  <button
                    type="button"
                    onClick={() => setIsRightPanelOpen(!isRightPanelOpen)}
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 ml-3"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open right panel</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2.5}
                      stroke="currentColor"
                      className="size-8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <DisclosurePanel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <ScrollLink
                  ScrollLink
                  key={item.name}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  duration={500}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </ScrollLink>
                ))}
              </div>
            </DisclosurePanel>

            {/* Right-side sliding panel */}
            <div
              className={`fixed inset-y-0 right-0 z-40 mt-4 transform transition-transform duration-300 ease-in-out ${isRightPanelOpen ? "translate-x-0" : "translate-x-full"
                }`} style={{ marginTop: "20px" }}
            >
              <div
                className="relative w-64 h-full bg-gray-800 p-6"
                onClick={(e) => e.stopPropagation()}
                style={{ marginTop: "25px" }} >
                <button
                  type="button"
                  className="absolute top-0 right-0 mt-4 mr-4"
                  onClick={() => setIsRightPanelOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6 text-white" />
                </button>
                <div className="space-y-1">
                  {navigationcalender.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <Element name="home" className="section">
        <Header />
      </Element>

      <Element name="about" className="section">
        <AboutUs />
      </Element>

      <Element name="event-section" className="section">
        <EventSection />
      </Element>
      <Element name="achivment" className="section">
        <Achivment />
      </Element>
      <Element name="contact" className="section">
        <Contact />
        <Footer />
      </Element>

    </>
  );
}
