import React from 'react'
import TestNavbar from '../components/teastpage/TestNavbar'
import JobRollAnt from '../componentHome/fromtab/JobRollAnt'

const JobRollAntPage = () => {
  return (
    <TestNavbar>
   
        <JobRollAnt></JobRollAnt>
  </TestNavbar>
  )
}

export default JobRollAntPage