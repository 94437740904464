// src/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://dev.littera.in/Litteracore/api', // Replace with your API's base URL
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'UHJhdGhhbXNvZnRTZWNyZXRLZXkxMjM=', // Replace with your API key
  },
});

export default axiosInstance;
