// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import LitteraRoom1 from '../components/Content/LitteraRoom1';
// import TestNavbar from '../components/teastpage/TestNavbar';
// import { useConfig } from '../ConfigContext';
// import { useFeedback } from '../Context/FeedbackContext';
// import Loader from './Loader';

// const Litteraroom = () => {
//   const config = useConfig();
//   const { roomSessions, setroomSessions } = useFeedback();
//   const { id } = useParams();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const getSessions = async () => {
//       if (id) {
//         const headers = {
//           Accept: 'application/json, text/plain',
//           APIKey: config?.REACT_APP_API_KEY,
//           'Content-Type': 'application/json',
//         };

//         try {
//           const { data } = await axios.get(`${config?.REACT_APP_API_URL}/TrgSessions?trainingid=${id}`, { headers });
//           console.log('Training data', data);
//           setroomSessions(data);
//           console.log("roomSessions" , roomSessions)
//           setLoading(false);
//         } catch (error) {
//           console.log('Error fetching sessions', error);
//           setLoading(false);
//         }
//       } else {
//         console.log('No id provided');
//         setLoading(false);      
//       }
//     };

//     getSessions();

//   }, [id, config]);

//   if (loading) {
//     return <Loader/>
//   }

//   return (
//     <TestNavbar>
//       <LitteraRoom1 />
//     </TestNavbar>
//   );
// };

// export default Litteraroom;


import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LitteraRoom1 from '../components/Content/LitteraRoom1';
import TestNavbar from '../components/teastpage/TestNavbar';
import { useConfig } from '../ConfigContext';
import { useFeedback } from '../Context/FeedbackContext';
import Loader from './Loader';

const Litteraroom = () => {
  const config = useConfig();
  const { roomSessions, setroomSessions } = useFeedback();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSessions = async () => {
      if (id) {
        const headers = {
          Accept: 'application/json, text/plain',
          APIKey: config?.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        };

        try {
          const { data } = await axios.get(`${config?.REACT_APP_API_URL}/TrgSessions?trainingid=${id}`, 
            { 
              headers,
              params:{
                pagetype:1
              } 
            });
          console.log('Training data', data);
          // localStorage.setItem("roomsessiondata" , JSON.stringify(data))
          setroomSessions(data);
          setLoading(false);
        } catch (error) {
          console.log('Error fetching sessions', error);
          setLoading(false);
        }
      } else {
        console.log('No id provided');
        setLoading(false);      
      }
    };

    getSessions();

  }, [id, config, setroomSessions]);
  

  if (loading) {
    return <Loader/>
  }

  return (
    <TestNavbar>
      <LitteraRoom1 />
    </TestNavbar>
  );
};

export default Litteraroom;
