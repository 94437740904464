import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { TiArrowBack, TiArrowForward } from 'react-icons/ti';
import { useFeedback } from '../../Context/FeedbackContext';
import { toast } from 'react-toastify';

const Tabs = ({ filename, sendfile, closefile }) => {
    const { 
        selectedSessionId,
        setLink,
        htmlid,
        sethtmlid,
        Link,
        tabs, 
        setTabs,
        setwhiteboard
    } = useFeedback()
    const [activeTab, setActiveTab] = useState(null); 
    const [showBackButton, setShowBackButton] = useState(false);
    const [showForwardButton, setShowForwardButton] = useState(false);
    const documentNavRef = useRef(null);   

    useEffect(() => {
        updateScrollButtons(); 
    }, [tabs]);

    useEffect(() => {
        const handleScroll = () => {
            updateScrollButtons();
        };
        if (documentNavRef.current) {
            documentNavRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (documentNavRef.current) {
                documentNavRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const updateScrollButtons = () => {
        if (documentNavRef.current) {
            setShowBackButton(documentNavRef.current.scrollLeft > 0);
            setShowForwardButton(documentNavRef.current.scrollLeft < (documentNavRef.current.scrollWidth - documentNavRef.current.clientWidth));
        }
    };

    const scrollLeft = () => {
        if (documentNavRef.current) {
            const newPosition = documentNavRef.current.scrollLeft - 100;
            documentNavRef.current.scrollTo({
                left: newPosition,
                behavior: 'smooth'
            });
            updateScrollButtons();
        }
    };

    const scrollRight = () => {
        if (documentNavRef.current) {
            const newPosition = documentNavRef.current.scrollLeft + 100;
            documentNavRef.current.scrollTo({
                left: newPosition,
                behavior: 'smooth'
            });
            updateScrollButtons();
        }
    };

    useEffect(()=>{
        closeAllTabs() 
        localStorage.setItem("VideoAnalytics", JSON.stringify(null));  
        setTabs([])
        setLink([])
    } , [ selectedSessionId ])

    useEffect(() => {
        if (filename && !tabs.some(tab => tab === filename)) {
            setTabs(prevTabs => [...prevTabs, filename]);
        }
        setActiveTab(filename);
    }, [filename , tabs]);

    const openfile = (file) => {
        const videoIdToFind = file.ttsam_globalcontentid ;
        const storedDataString = JSON.parse(localStorage.getItem("VideoAnalytics"));        
        if (storedDataString) {
            try {        
                const foundData = storedDataString.find(data => data.videoId === videoIdToFind);
                if(activeTab != file){
                    if (foundData) {
                        sendfile({ ...file, foundData });
                        setActiveTab(file)
                    }
                    else {
                        sendfile({ ...file });
                        setActiveTab(file)
                    }
                }
            } catch (error) {
                console.error("Error parsing stored data:", error);
            }
        }  
        else {
            sendfile({ ...file });
            setActiveTab(file)
        }
    };
    
    const closeTab = async (index, tab) => {
        console.log("closingtab" , tab)
        const updatedTabs = tabs.filter((_, idx) => idx !== index);
        setLink(prevLink => {
            const foundIndex = prevLink.findIndex(item => item.htmlid === tab?.ttsam_id);
            if (foundIndex !== -1) {
                return [
                    ...prevLink.slice(0, foundIndex),
                    ...prevLink.slice(foundIndex + 1),
                ];
            }
            return prevLink;
        });
        // const foundIndex = Link && Link?.findIndex(item => item.htmlid === tab?.ttsam_id);
        // if (foundIndex !== -1) {
        //     if(foundIndex){
        //         Link.splice(foundIndex, 1);
        //     }
        // }
        if(tab?.sessionAttachmentType === "whiteboard"){
            setwhiteboard(false)
        }

        setTabs(updatedTabs);
        await closefile(tab);
        
        const videoIdToRemove = tab.ttsam_globalcontentid ;
        const storedDataString = JSON.parse(localStorage.getItem("VideoAnalytics"));
        
        
        if (storedDataString) {
            const updatedData = storedDataString.filter(data => data.videoId !== videoIdToRemove);
            localStorage.setItem("VideoAnalytics", JSON.stringify(updatedData));
        }
        
        if (index < updatedTabs.length) {
            const nextFile = updatedTabs[index];
            if (nextFile) {
                openfile(nextFile);
                setActiveTab(nextFile);
            }
        } 
        else if (updatedTabs.length > 0) {
            const lastFile = updatedTabs[updatedTabs.length - 1];
            openfile(lastFile);
            setActiveTab(lastFile);
        } 
        else {
            setActiveTab(false);
            closeAllTabs()
            setLink([])
            setTabs([]);
        }
    };

    const closeAllTabs = () => {        
        tabs.forEach(tab => {
            closefile(tab);

            const videoIdToRemove = tab.ttsam_globalcontentid ;
            const storedDataString = JSON.parse(localStorage.getItem("VideoAnalytics"));
            
            if (storedDataString) {
                const updatedData = storedDataString.filter(data => data.videoId !== videoIdToRemove);
                localStorage.setItem("VideoAnalytics", JSON.stringify(updatedData));
            }
        });
        setActiveTab(null);
        setLink([])
        setTabs([]);
        setwhiteboard(false)
        sendfile(null)
    };

    return (
        <div className='h-14 py-2 w-full border-b-2 pl-2 max-phone:pr-2 max-md:pr-5 pr-2 flex items-center justify-between'>
            <div className='h-full max-phone:w-[68.5vw]  w-[50vw] gap-2 flex items-center'>
                {showBackButton && (
                    <button className='flex items-center justify-center border-2' onClick={scrollLeft}>
                        <TiArrowBack fontSize={18} />
                    </button>
                )}
                <div className={`h-full w-[44vw] max-phone:overflow-x-auto max-phone:w-[60vw]  ${showForwardButton ? "border-r-2" : "" }  gap-2 overflow-x-auto flex documentNav`} ref={documentNavRef}>
                    {tabs.map((tab, index) => (
                        <button key={index} 
                            className={`uppercase h-full w-fit border-2 px-2 whitespace-nowrap flex items-center ${activeTab === tab ? "border-[#428BCA]" : ''} gap-2`}>
                            <h6 
                                className='text-[1.1rem]' 
                                onClick={() => openfile(tab)}>
                                {tab.ttsad_title.split(' ').slice(0, 3).join(' ')}
                                {tab.ttsad_title.split(' ').length > 3 ? '...' : ''}
                            </h6>
                            <IoClose color='gray' fontSize={14} onClick={() => closeTab(index, tab)} />
                        </button>
                    ))}
                </div>
                {showForwardButton && (
                    <button className='flex items-center justify-center border-2' onClick={scrollRight}>
                        <TiArrowForward fontSize={18} />
                    </button>
                )}
            </div>
            {tabs.length > 0 ? (
                <button onClick={closeAllTabs} className='text-[1rem] min-w-fit uppercase border-2 pt-1 pb-1 px-2 flex items-center gap-2'>
                    Close All
                    <IoClose color='gray' fontSize={14} />
                </button>
            ) : (
                <div className='opacity-0 text-xs min-w-fit uppercase border-2 py-1 px-2 flex items-center gap-2'>
                    Close All
                    <IoClose color='gray' fontSize={14} />
                </div>
            )}
        </div>        
    );
};

export default Tabs;
