import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress } from '@mui/material';
import Breadcrumb from '../commonComponent/Breadcrumbs';
import { Input, Select } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { useConfig } from '../../ConfigContext';
import { useUser } from '../../UserContext';
import { Dropdown, Menu, } from 'antd';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PurePanel from 'antd/es/float-button/PurePanel';
const { Option } = Select;

const TestListTable = () => {

    const { selectedUserType } = useUser();
    const config = useConfig();
    const [tests, setTests] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const user = JSON.parse(localStorage.getItem('user'));
    const userid = user.userdetails.agencyid;
    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
             const pageSize = 10;  // Number of items per page
            const url = `${config.REACT_APP_EVAL_API_PATH}/Tests/UserTests?usertype=${selectedUserType}&userid=${userid}&PageNumber=${currentPage}&PageSize=${pageSize}`;
            try {
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'API-Key': config.REACT_APP_API_KEY,
                    }
                });

                // Logging data to debug
                console.log("API Response:", response.data);
               
                setTests(response.data || []);  // Ensure the data structure matches
                setTotalPages(Math.ceil(response.data.totalCount / pageSize));  // Adjust based on API response
                console.log("Response", tests)
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [config, selectedUserType, userid, currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSendButtonClick = async (tests, docStatus) => {
        const createdon = new Date().toISOString().slice(0, 19).replace("T", " ");
        const user = JSON.parse(localStorage.getItem("user")); // Retrieve user from localStorage
        if (!user) {
            toast.error("User data not found");
            return; 
        }
        console.log(tests, "test")

        const headers = {
            "Content-Type": "application/json",
            APIKey: config.REACT_APP_API_KEY,
        };
        const payload = {
            tttds_info_desc: null,
            doc_id: tests,
            createdon: createdon,
            createdby: user.userdetails.agencyid,
            branchid: user.userdetails.branchid,
            docremark: "",
            docdate: createdon,
            actiondate: createdon,
            CreatedBy_empid: user.userdetails.agencyid,
            fwd_empid: user.userdetails.agencyid,
            tat_type_id: 115,
            doc_status: docStatus,
            attached_doc: null,
            attached_doc_name: null,
            doctype: 0,
            draftletter: null,
            tttds_is_final: 0,
            tttds_letter_type: 0,
            docremarkenc: null,
            draftletterenc: null,
        };


        try {
            const response = await axios.post(`${config.REACT_APP_API_URL}/DOCUMENT_STATUS`, payload, {
                headers: headers,
            });
            toast.success("Data sent successfully");
        } catch (error) {
            console.error("Error sending data:", error);
            toast.error("Error sending data");
        }
    };





    const renderActionButton = (test) => {
        const handleActionClick = (status) => {
            handleSendButtonClick(test.testID, status);
        };
        const menu = (
            <Menu>
                {test.ttttt_status == 0 || test.ttttt_status == 9 ? (
                    <Menu.Item key="1" onClick={() => handleActionClick(9)}>
                        <Button style={{ fontSize: "15px" }}>Suspended</Button>
                    </Menu.Item>
                ) : (
                    <Menu.Item key="2" onClick={() => handleActionClick(1)}>
                        <Button style={{ fontSize: "15px" }}>Approve</Button>
                    </Menu.Item>
                )}

            </Menu>
        );
        console.log("test-type", test.type);

        if (test.type == 2 && selectedUserType == 5) {
            return <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "red",cursor:"pointer" }}></span>
        }

        if (test.type !== 2 && selectedUserType == 5) {
            if (test.testparticipantid == null) {
                return <span className="fa  fa-play" title=" Start Test " style={{ fontSize: "16px", margin: "6px", color: "red",cursor:"pointer" }}></span>
            } else {
                return <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "chocolate",cursor:"pointer" }}></span>
            }
        }

        if (selectedUserType == 4) {
            return <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "red",cursor:"pointer" }}></span>
        }

        if (selectedUserType == 1 || selectedUserType == 3) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                    <span className="glyphicon  glyphicon-dashboard" title=" View tst result " style={{ fontSize: "16px", margin: "6px", color: "chocolate", cursor: "pointer" }}></span>
                    <span className="fa  fa-edit" title="Edit" style={{ fontSize: "16px", margin: "6px", color: "chocolate", cursor: "pointer" }}></span>
                    <span className="glyphicon  glyphicon-remove" title="Remove" style={{ fontSize: "16px", margin: "6px", color: "red", cursor: "pointer" }} onClick={() => handleActionClick(-1)}></span>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <span className="glyphicon  glyphicon-cog" title="Change Status" style={{ fontSize: "16px", margin: "6px", color: "gray", cursor: "pointer" }} onClick={e => e.preventDefault()}></span>
                    </Dropdown>
                </div>
            );
        }

        if (test.status == 0 || test.status == 9) {
            return <Button variant="contained" color="warning">Suspended</Button>;
        } else {
            return <Button variant="contained" color="warning"> Action</Button>;
        }
    };



    const paths = [
        { title: 'Dashboard', url: '/dashboard' },
        { title: 'Test List', url: '/Eval/TestList' }
    ];

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <p>Error fetching data: {error.message}</p>
            </div>
        );
    }

    return (
        <>
            <Breadcrumb paths={paths} />
            <div style={{ display: 'flex', gap: '10px', padding: '16px', backgroundColor: '#E4E7EA', width: '100%' }}>
                <Input
                    placeholder="Search Test-List"
                    style={{ width: '80%', padding: '8px' }}
                />
                <Select
                    placeholder="Training Code"
                    style={{ width: '25%', height: '40px' }}
                    bordered
                >
                    <Option value="testName">Titel</Option>
                    <Option value="trainingcategoryname">Category</Option>
                    <Option value="skillTag">Skill Tags</Option>
                    {/* Add more Option elements here */}
                </Select>
            </div>
            <div className="table-container">
                <TableContainer component={Paper} style={{ width: "96%", margin: "auto", marginTop: "10px" }}>
                    <Table>
                        <TableHead style={{ backgroundColor: "rgb(66, 139, 202)" }}>
                            <TableRow>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Training Code</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Title</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Category</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Skill Tags</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Date/Day
                                </TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Start Time</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>No of Questions</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Mark per Question</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Duration (min)</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Status</TableCell>
                                <TableCell style={{ borderLeft: '2px solid #ddd' }} className='feedbck-titel'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tests.filter(test => test.type == 1).map((test, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.trainingCode}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.testName}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.trainingcategoryname}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.skillTag}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {test.type ==2 ? test.ttttt_session_day : test.ttttt_session_time}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.start_time || 'N/A'}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.noOfQuestion}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.mark_per_question}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">
                                        {test.assesmentTime * test.noOfQuestion}
                                    </TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }} className="feedback-row">{test.isActive ? 'Active' : 'Inactive'}</TableCell>
                                    <TableCell style={{ borderLeft: '2px solid #ddd' }}>
                                        <TableCell variant="contained" color="primary" style={{ display: 'flex', flexDirection: 'row', borderBottom: "none" }}>
                                            {renderActionButton(test)}
                                        </TableCell>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </div>
            <div className="card-container">
                {tests.filter(test => test.type == 1).map((test, index) => (
                    <div className="card" key={index}>
                        <div className="card-header">
                            {test.trainingCode}
                        </div>
                        <div className="card-body">
                            <p>Title:{test.testName}</p>
                            <p>Category: {test.trainingcategoryname}</p>
                            <p>Skill Tags: {test.skillTag}</p>
                            <p>Date: {new Date(test.createdOn).toLocaleDateString()}</p>
                            <p>Start Time: {test.start_time || 'N/A'}</p>
                            <p>No of Questions:{test.noOfQuestion}</p>
                            <p>Mark per Question:{test.mark_per_question}</p>
                            <p>Duration (min): {test.ttttt_session_duration}</p>
                            <p>Status: {test.isActive ? 'Active' : 'Inactive'}</p>
                            <TableCell variant="contained" color="primary" style={{ display: 'flex', flexDirection: 'row', borderBottom: "none" }}>

                                {renderActionButton(test)}



                            </TableCell>
                        </div>

                    </div>
                ))}
            </div>
            <div className="pagination-container" style={{ display: 'flex', justifyContent: 'end', padding: '16px' }}>
                <Button
                    variant="contained"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{ marginRight: "5px" }}>
                    Previous
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>

            </div>


        </>
    );
};

export default TestListTable;
