import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'; // Corrected import
import { useConfig } from './ConfigContext';

const AutoLogout = ({ children }) => {
    const config= useConfig
    const navigate = useNavigate();
    const idleTimeout = parseInt(config.REACT_APP_IDLE_TIMEOUT, 10) || 1800; // Corrected variable name

    const handleOnIdle = () => {
        console.log("User is idle");
        localStorage.removeItem('user');
        navigate('/login-page');
    };

    const handleOnActive = (event) => {
        console.log("User is active", event);
    };

    const handleOnAction = (event) => {
        console.log("User did something", event);
    };

    useIdleTimer({
        timeout: idleTimeout * 1000,
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        onActive: handleOnActive,
        debounce: 500
    });

    return (
        <>
            {children}
        </>
    );
};

export default AutoLogout;
