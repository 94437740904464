import React, { useEffect, useState } from 'react'
import { useFeedback } from '../../Context/FeedbackContext'
import { v4 as uuidv4 } from "uuid";
import { useConfig } from '../../ConfigContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';


const Notes1 = ({showNotes}) => {
  const [textarea, settextarea] = useState('')
  const [messages, setmessages] = useState([])
  const { id } = useParams()
  const user = JSON.parse(localStorage.getItem('user'))
  const config = useConfig()
  const { 
    selectedSessionId,
    Notes,
    setNotes,
    Loading,
    setLoading

  } = useFeedback()


  useEffect(()=>{
    getSessionNotes()
    console.log("notes",Notes)
  },[selectedSessionId, config ])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      createNotes(e);
    }
  };

  const getSessionNotes = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };

      try {

        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/SessionNotes`, {
            headers: headers,
            params: {
                userid:user?.userdetails?.agencyid ,sessionid:selectedSessionId , trainingid:id 
            }
        });
        
        console.log("notes parameter data" , {
            userid:user?.userdetails?.agencyid ,sessionid:selectedSessionId , trainingid:id 
        })
        console.log('Session notes', data);
        setNotes(data);
        setLoading(false);

      } catch (error) {
        console.log("error" ,{
            userid:user?.userdetails?.agencyid ,sessionid:selectedSessionId , trainingid:id 
        })
        console.log('Error fetching sessions content', error);
        setLoading(false);
      }
    } else {
      console.log('No id provided');
      setLoading(false);      
    }
  };

  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return dateTimeString;
  }

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const createNotes = async (e) => {
    e.preventDefault();
  
    if (textarea.trim() === "") {
      toast.error("Add some input");
      return;
    }
  
    if (!id) {
      console.log('No id provided');
      setLoading(false);
      return;
    }
  
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    }; 
  
    const postData = {
      ttsn_id: uuidv4(),
      ttsn_training_id: id,
      ttsn_session_id: selectedSessionId,
      ttsn_notes: [
        {
          notes: textarea,
          createdon: getCurrentDateTime()
        }
      ],
      ttsn_created_by: user?.userdetails?.agencyid,
      ttsn_createdon: getCurrentDateTime()
    };
  
    try {
      const { data } = await axios.post(`${config?.REACT_APP_API_URL}/SessionNotes`,
        postData , 
        { headers:headers }
      );
  
      console.log('Session notes posted successfully:', data);
      getSessionNotes();
      setLoading(false);
      settextarea('');
    } catch (error) {
      console.error('Error posting session notes:', error);
      console.error('prameters', postData);
    } finally {
      setLoading(false);
      settextarea('');
    }
  };

  return (
    <div className='p-2 max-md:h-full datadiv flex flex-col items-center w-full border-b-2 gap-2 '>
        <h4 className='uppercase text-2xl'>Notes</h4>

        <div className='w-full max-md:max-h-[100vh] items-center overflow-y-auto flex flex-col gap-2 commentswrapper border-2 p-2'>

        {
          Notes?.length > 0 ?
            <div className='w-full h-full'>
                {
                    Notes[0]?.ttsn_notes.map((item, index) => (
                      <p
                        className='w-full text-2xl px-2 pt-5 pb-0 border-2 flex-shrink-0 flex flex-col justify-center ' 
                        key={index}
                      >
                      <p 
                        className='notesfont text-sm '
                      >
                        {formattedDate(item.createdon)}    
                      </p>

                      <p>
                        {item.notes}
                      </p>

                      </p>
                    ))
                }

            </div>
          :
          <h1 className='capitalize text-xl'>Notes are empty...</h1>          
        }
        </div>

        <div className='w-full border-t-2 pt-2 flex  justify-between items-center '>
          <input type="text" 
            required
            onKeyDown={handleKeyPress} value={textarea} onChange={(e)=>settextarea(e.target.value)} 
            className='messagetextarea border-2 px-2 w-[70%] h-16' placeholder='Enter Your Message...'
          />
          <button onClick={createNotes} type='submit' className='max-md:h-14 h-[75%] px-4 w-30 text-white bg-[#007bff] rounded-md font-white text-sm '>Add Note</button>
        </div>
    </div>

  )
}

export default Notes1