import React from "react";
import Navbar from "../components/navbar/Navbar";
import MainpainalPage from "../components/dasboard/MainpainalPage";
import TestNavbar from "../components/teastpage/TestNavbar";

const DasboardPage = () => {
  
  return (
    <TestNavbar>
      <MainpainalPage />
     
    </TestNavbar>
  );
};

export default DasboardPage;
