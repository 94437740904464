import React, { useEffect, useRef } from 'react';
import { Player, ControlBar, PlayToggle } from 'video-react';
import 'video-react/dist/video-react.css';
import { useConfig } from '../../ConfigContext';
import { useFeedback } from '../../Context/FeedbackContext';
import DocumentViewer from './DocumentViewer ';

const Universal = ({ docs }) => {
    const wrapperRef = useRef(null);
    const videoRefs = useRef({});
    const config = useConfig();
    const { Video, Audio, pdf, WYISIWYG, Image } = useFeedback();
    
    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        const handleKeyDown = (e) => {
            if (e.ctrlKey && (e.key === 's' || e.key === 'S')) {
                e.preventDefault();
                console.log("Ctrl+S disabled.");
            }
        };

        const handleInspectElement = (e) => {
            if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
                e.preventDefault();
                console.log("Attempted to inspect element inside docviewerwrapper.");
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        if (wrapperRef.current) {
            wrapperRef.current.addEventListener('mousedown', handleInspectElement);
        }

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('keydown', handleKeyDown);
            if (wrapperRef.current) {
                wrapperRef.current.removeEventListener('mousedown', handleInspectElement);
            }
        };
    
    }, []);

    useEffect(() => {
        console.log("docs:", docs);
    }, [docs]);

    const updateAnalytics = (videoId, newData) => {
        let analyticsData = JSON.parse(localStorage.getItem("VideoAnalytics")) || [];
        const index = analyticsData.findIndex(item => item.videoId === videoId);
        if (index !== -1) {
            analyticsData[index] = {
                videoId,
                ...newData
            };
        } else {
            analyticsData.push({
                videoId,
                ...newData
            });
        }
        localStorage.setItem("VideoAnalytics", JSON.stringify(analyticsData));  
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainderSeconds = Math.floor(seconds % 60);
        const formattedTime = `${minutes}:${remainderSeconds < 10 ? '0' : ''}${remainderSeconds}`;
        return formattedTime;
    };

    const handleSeek = (e, videoId) => {
        const currentTime = e.target.currentTime;
        updateAnalytics(videoId, { currentTime: formatTime(currentTime) });
    };

    const timeStringToSeconds = (timeString) => {
        if (!timeString) return 0;
        const [minutes, seconds] = timeString.split(':').map(parseFloat);
        return minutes * 60 + seconds;
    };

    const documents = Array.isArray(docs) ? docs : [docs];

    const docum = documents.map(doc => {
        if (doc.sessionAttachmentType == 4) {
            return { ...doc, isVideo: true };
        } else {
            return { ...doc, isVideo: false };
        }
    });

    const videos = docum.filter(doc => doc.isVideo);
    const otherDocuments = docum.filter(doc => !doc.isVideo);

    return (
        <div ref={wrapperRef} className={`docviewerwrapper overflow-y-auto ${videos.length > 0 ? "max-phone:h-[29vh]" : "h-[75vh]"} ${otherDocuments.length > 0 ? "max-phone:h-[55vh]" : ""} overflow-x-hidden h-[75vh] w-full p-2 border-b-2`}>
            <div className='h-full'>
                {otherDocuments?.length > 0 &&
                    otherDocuments?.map((item, index)=>(
                        item.sessionAttachmentType == 5 ?                         
                            <div className='h-full w-full'>
                                <img 
                                    className='w-full object-cover' 
                                    src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${item.globalFilePath}`} 
                                    alt={item.globalFilePath} 
                                />
                            </div>
                        :
                        item.sessionAttachmentType == 1 ?                         
                            // <DocViewer
                            //     style={{ height: "100%" }}
                            //     prefetchMethod='GET'
                            //     documents={otherDocuments?.map(doc => ({
                            //         uri:  `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${doc?.globalFilePath}`,
                            //         fileType: doc.sessionAttachmentType == 1 ? 'application/pdf' : 'other',
                            //         fileName:doc.globalFilePath
                            //     }))}
                            //     pluginRenderers={DocViewerRenderers}
                            //     sandbox="allow-scripts"
                            // />
                            <>   
                            <div className='h-full  w-[50vw]'>
                                <DocumentViewer pdf={otherDocuments} />
                            </div>
                            </>
                            :
                            // item.sessionAttachmentType === "whiteboard" || item.sessionAttachmentType === "9" ?
                            item.sessionAttachmentType === "whiteboard"  ?
                            <div className='h-full  w-full'>
                                <iframe className='h-full w-full' src={item.Link} frameborder="0"></iframe>
                            </div>
                            :

                        null
                    ))
                }
                {videos.map((video, index) => {
                    const initialStartTime = docs?.foundData?.currentTime !== undefined ? timeStringToSeconds(docs.foundData.currentTime) : 0;

                    return (
                        <div key={video.ttsam_globalcontentid} className='h-full w-full relative'>
                            <Player
                                sandbox="allow-scripts"
                                autoPlay
                                fluid={false}
                                width='100%'
                                height='100%'
                                poster={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${video?.globalFilePath}`}
                                src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${video?.globalFilePath}`}
                                disableDefaultControls={false}
                                onContextMenu={(e) => e.preventDefault()}
                                onTimeUpdate={(e) => {
                                    const currentTime = e.target.currentTime;
                                    const duration = e.target.duration;
                                    const videoId = video.ttsam_globalcontentid;

                                    if (currentTime > 0) {
                                        updateAnalytics(videoId, {
                                            currentTime: formatTime(currentTime),
                                            duration: formatTime(duration)
                                        });
                                        videoRefs.current[videoId] = { ...videoRefs.current[videoId], currentTime, duration };
                                    }
                                }}
                                onSeeking={(e) => handleSeek(e, video.ttsam_globalcontentid)}
                                startTime={initialStartTime}
                            >
                                <ControlBar autoHide={true}>
                                    <PlayToggle />
                                </ControlBar>
                            </Player>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Universal;
