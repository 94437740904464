import React, { useEffect, useState } from 'react'
import { FaCertificate } from "react-icons/fa"
import { BsTable } from "react-icons/bs"
import { TbCheckbox } from "react-icons/tb"
import { MdPendingActions , MdOutlineAssessment } from "react-icons/md"
import { IoIosTimer, IoIosVideocam } from "react-icons/io"
import { LuCalendarDays } from "react-icons/lu"
import { useConfig } from '../../ConfigContext'
import { useUser } from '../../UserContext'
import { useFeedback } from '../../Context/FeedbackContext'
import axios from 'axios'
import Loader from '../../pages/Loader'



const TrainingsDashboard = () => {
    const [activeButton, setActiveButton] = useState('myTrainings'); 
    const config = useConfig()
    const { 
        loading ,
        setLoading , 
        dashboard_analytics, 
        setdashboard_analytics 
    } = useFeedback()
    const { 
        selectedYear , selectedUserType, 
        startYear, setStartYear , 
        endYear, setEndYear 
    } = useUser()
    const user = JSON.parse(localStorage.getItem('user'));

    const handleToggle = (buttonType) => {
      setActiveButton(buttonType); 
    };
  
    const cardsdata = [
        {
            id:"1",
            title : "Completed",
            Logo : <TbCheckbox fontSize={30}/>,
            background: "bg-[#5CB85C]",
            numbers: dashboard_analytics?.trg_completed
        },
        {
            id:"2",
            title : "Certificate",
            Logo : <FaCertificate fontSize={30}/>,
            background: "bg-[#337AB7]",
            numbers: dashboard_analytics?.no_of_certificates
        },
        {
            id:"3",
            title : "Upcoming Training",
            Logo : <BsTable fontSize={30}/>,
            background: "bg-[#4E5154]",
            numbers:dashboard_analytics?.upcoming_trg
        },
        {
            id:"4",
            title : "Pending Test",
            Logo : <MdPendingActions fontSize={30}/>,
            background: "bg-[#5BC0DE]",
            numbers:dashboard_analytics?.no_of_pending_tests
        },
        {
            id:"5",
            title : "Pending Assesment",
            Logo : <MdOutlineAssessment fontSize={30}/>,
            background: "bg-[#F0AD4E]",
            numbers:dashboard_analytics?.no_of_pending_assignment
        },
    ]

    const trainingdata = [
        {
            title : "ASP.NET Development Training",
            imagepath: "http://p9:8888//Training_Upload/Content/8857ac58-7852-3d46-36d5-0c9b519086e4-0ce3e2a6-63cf-fc04-8c89-1667dd566981-about_littera.jpg",
            start: "20-02-2024",
            end: "20-06-2024",
            content:"15",
            duration:"4 months"
        },
        {
            title : "React Development Training",
            start: "11-09-2024",
            imagepath: "http://p9:8888//Training_Upload/Content/8b92f960-3aaf-7cf4-ab6c-6d8bbbd6d9b1-client_icon.jpg",
            end: "11-12-2024",
            content:"8",
            duration:"3 months"
        },
        {
            title : "Mern Stack Development",
            start: "24-07-2024",
            imagepath: "http://p9:8888//Training_Upload/Content/8857ac58-7852-3d46-36d5-0c9b519086e4-0ce3e2a6-63cf-fc04-8c89-1667dd566981-about_littera.jpg",
            end: "24-01-2025",
            content:"25",
            duration:"6 months"
        },
        {
            title : "Lakshya Nirdharan",
            start: "11-09-2024",
            imagepath: "http://p9:8888//Training_Upload/Content/8b92f960-3aaf-7cf4-ab6c-6d8bbbd6d9b1-client_icon.jpg",
            end: "11-10-2024",
            content:"6",
            duration:"30 Days"
        },
        {
            title : "Goal Setting",
            start: "24-07-2024",
            imagepath: "http://p9:8888//Training_Upload/Content/8857ac58-7852-3d46-36d5-0c9b519086e4-0ce3e2a6-63cf-fc04-8c89-1667dd566981-about_littera.jpg",
            end: "30-07-2025",
            content:"3",
            duration:"6 Days"
        },
        {
            title : "My Sql Training",
            start: "11-09-2024",
            imagepath: "http://p9:8888//Training_Upload/Content/8b92f960-3aaf-7cf4-ab6c-6d8bbbd6d9b1-client_icon.jpg",
            end: "11-10-2024",
            content:"6",
            duration:"30 Days"
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            if (selectedYear) {
                await getDashboardAnalytics();
            }
        };
    
        fetchData();
    }, [selectedUserType,selectedYear]);
    
    const getDashboardAnalytics = async () => {
        if (user) {
            setLoading(true);
            const headers = {
                Accept: 'application/json, text/plain',
                APIKey: config?.REACT_APP_API_KEY,
                'Content-Type': 'application/json',
            };
    
            try {
                const startDate = `${selectedYear.split('-')[0]}-04-01`;
                const endDate = `${selectedYear.split('-')[1]}-03-31`;

                const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Dashboard_analytics`, {
                    headers: headers,
                    params: {
                        usertype: selectedUserType,
                        userid: user?.userdetails?.agencyid,
                        startdate: startDate,
                        enddate: endDate,
                    }
                });
                setdashboard_analytics(data);
                console.log('Dashboard data', data);
                setLoading(false);
    
            } catch (error) {
                console.log('Error Dashboard data', error);
                setLoading(false);
            }
        } else {
            console.log('No user provided');
            setLoading(false);
        }
    };
    
    if(loading){
        return <Loader/>
    }

    return (
    <div className='h-full w-full'>

        <div className="grid grid-cols-5 max-xl:grid-cols-3 max-lg:grid-cols-1 max-xl:gap-4 max-xl:gap-y-0 gap-3">
            {
                cardsdata?.map((item,index)=>(                    
                    <div
                        key={item.id}
                        className={`panel panel-success-alt noborder`}>
                        <div
                            className={`panel-heading noborder ${item.background} h-full `}>
                            <div className="panel-icon text-black flex items-center justify-center ">
                                {item.Logo}
                            </div>
                            <div className="media-body">
                                <h6 className="uppercase text-[1.1rem] nomargin">{item.title}</h6>
                                <h2 className="mt5">{item.numbers}</h2>
                            </div>
                        </div>
                    </div>                                
                ))
            }
        </div>

        <div className='w-full p-8 bg-[#E4E7EA] flex gap-10 '>
            <input type="text" placeholder='Search Training' className='w-[80%] py-1 px-4' />
            <select className='cursor-pointer py-3 h-full w-96 border-2'>  
                <option className='w-full' value="">Training Code</option>
            </select>
        </div>

        <div className='w-full flex justify-end my-6'>
                <button
                    className={`py-4 px-8 max-sm:px-4 max-sm:py-2 font-medium max-sm:text-lg min-w-60 ${
                    activeButton === 'upcoming' ? 'bg-[#337AB7] text-white rounded-l-lg' : 'bg-[#ececec] text-black rounded-l-lg'
                    }`}
                    onClick={() => handleToggle('upcoming')}
                >
                    Upcoming Trainings
                </button>
                <button
                    className={`py-4 px-2 font-medium max-sm:text-lg w-52 ${
                    activeButton === 'myTrainings' ? 'bg-[#337AB7] text-white rounded-r-lg' : 'bg-[#ececec] text-black rounded-r-lg'
                    }`}
                    onClick={() => handleToggle('myTrainings')}
                >
                    My Trainings
                </button>
        </div>

        <div className='h-full w-full grid grid-cols-3 max-lg:grid-cols-2 max-mid:grid-cols-1 gap-4'>
            {
                trainingdata.map((item , index)=>(
                    <div
                        key={index}
                        // className='max-w-xl cursor-pointer rounded overflow-hidden shadow-lg bg-white mr-0 mt-4  ml-4 transition-transform transform duration-300 ease-in-out hover:scale-105 hover:shadow-3xl hover:bg-gray-100'
                        className='min-w-xl max-mid:min-w-full cursor-pointer rounded overflow-hidden shadow-lg bg-white transition-transform transform duration-300 ease-in-out hover:scale-105 hover:shadow-3xl hover:bg-gray-100'
                        >
                        <div className='h-64 max-mid:h-80 max-sm:h-64 overflow-hidden'>
                            <img
                                className='w-full h-full object-cover'
                                src={`${config?.LITTERA_CDN_BASE_URL}/theme/assets/images/2.jpg`}                                
                                alt="ASP.Net"
                            />
                        </div>    
                        <div className='p-4 pt-6 flex flex-col gap-2'>
                            <div className='w-full flex items-start justify-between'>
                            <span className='font-bold text-2xl text-gray-900'>{item.title}</span>
                            <span className='text-[#428bca] font-semibold'>Started</span>
                            </div>
                            <p className='text-gray-700 mt-2 text-2xl'>Interactive</p>
                            <span className="w-full flex flex-col gap-1 text-gray-600">
                            <div className='flex gap-2'>
                                <IoIosTimer fontSize={18} />
                                <p className='pt-.5 flex items-end'>
                                {item.start} to {item.end}
                                </p>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex gap-4">
                                <div className='flex gap-2'>
                                    <LuCalendarDays/>
                                    <p className='pt-.5 flex items-end capitalize'>
                                    {item.duration}
                                    </p>
                                </div>
                                <div className='flex gap-2'>
                                    <IoIosVideocam fontSize={18}/>
                                    <p className='pt-.5 flex items-end'>
                                    {item.content}
                                    </p>
                                </div>
                                </div>
                                <div className="text-blue-500">
                                <button className='text-sm border-blue-500 rounded-full border px-3 py-2 hover:bg-blue-500 hover:text-white transition'>
                                    Overview
                                </button>
                                </div>
                            </div>
                            </span>
                        </div>           
                    </div>
                ))
            }                      
        </div>
    </div>
  )
}

export default TrainingsDashboard