import React, { useEffect, useState, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { GoClock } from 'react-icons/go';
import { IoPrintOutline } from 'react-icons/io5';
import { CiSearch } from 'react-icons/ci';
import { GiSpeaker } from 'react-icons/gi';
import { HiPhone } from 'react-icons/hi';
import { MdEmail } from 'react-icons/md';
import Breadcrumb from '../../../componentHome/commonComponent/Breadcrumbs';
import { useFeedback } from '../../../Context/FeedbackContext';
import { useConfig } from '../../../ConfigContext';
import { useUser } from '../../../UserContext';
import axios from 'axios';
import { Tooltip } from 'react-tooltip';
import Loader from '../../../pages/Loader';
import { IoClose } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import CustomDayCell from './CustomDayCell.jsx';


Modal.setAppElement('#root');

const CreateSessionFromCalendar = () => {
  const config = useConfig()
  const { selectedDate, setSelectedDate , showModal, setShowModal, sessionType , loading , setLoading ,allfaculty, setallfaculty } = useFeedback()
  const [eventTitle, setEventTitle] = useState('');
  const [selectedTraining, setSelectedTraining] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [day, setDay] = useState('');
  const [week, setWeek] = useState('');
  const [durationTime, setDurationTime] = useState('');
  const [durationUnit, setDurationUnit] = useState('min');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [module, setModule] = useState('');
  const [faculty, setFaculty] = useState('');
  const [filtercontent, setfiltercontent] = useState(false);
  // const [allfaculty, setallfaculty] = useState(null);

  console.log("sessionType", sessionType)

  const handleFilter = (filterdata) => {
    setfiltercontent(filterdata.value)
  }

  const [time, setTime] = useState(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  });

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*\.?\d*$/.test(value)) {
      setDurationTime(value);
    }
  };

  // useEffect(()=>{
  //   const fetchData = async()=>{
  //     await getFaculty()
  //     console.log("factoes", allfaculty)
  //   }
  //   fetchData()

  // },[])

  const getFaculty = async () => {
    setLoading(true);
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    try {
      const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Agency`, {
        headers: headers,
        params: {
          agencytype: "00054",
        }
      }
      );
      setallfaculty(data);
      console.log("faculties",data)
      setLoading(false);
    } catch (error) {
      console.log('Error session type', error);
      setLoading(false);
    }
  };

  if (loading) {
    <Loader />
  }

  return (
    <div className='fixed z-99 top-0 left-0 right-0 bottom-0  bg-gray-700 bg-opacity-50 flex justify-center items-center'>
      <Draggable handle=".modal-header">
        <div className='bg-white py-6 border-[#428BCA] rounded shadow-lg'>
          <div className="modal-header px-6  cursor-move pb-4 border-b-2 flex items-center justify-between ">
            <span className='text-3xl'>Create Session</span>
            <IoClose
              cursor={"pointer"}
              onClick={() => setShowModal(false)}
            />
          </div>

          <div className='flex  flex-col max-h-[55vh] overflow-x-hidden overflow-y-auto  px-6 py-4 gap-4'>
                    
                    <div className='flex w-[45vw] max-mid:w-[65vw] max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center'>
                      <span className='w-40 '>Training <span className='text-red-600'>*</span></span>
                      <select
                        className='w-full rounded-md px-1 py-2 border-2'
                        value={selectedTraining}
                        onChange={(e) => setSelectedTraining(e.target.value)}
                      >
                        <option value="">Select Training</option>
                      </select>
                    </div>

                    <div className='flex w-[45vw] max-mid:w-[65vw] max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center'>
                      <span className='w-40 '>Type <span className='text-red-600'>*</span></span>
                      <Menu as="div" className="relative w-full inline-block text-left">
                        <div className="w-full ">
                          <MenuButton
                            className="inline-flex h-11 w-full items-center justify-between pl-4 pr-1 relative gap-x-1.5 overflow-hidden rounded-md bg-white py-2 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
                          >
                            {(() => {
                              const session = sessionType?.find(item => item.value === filtercontent);

                              if (session) {
                                return (
                                  <>
                                    <span className="flex items-center  justify-start gap-4">
                                      {session.Logo}
                                      <span>{session.title}</span>
                                    </span>
                                  </>
                                );
                              } else {
                                return 'Select Content';
                              }
                            })()}
                            <ChevronDownIcon
                              aria-hidden="true"
                              className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
                            />
                          </MenuButton>
                        </div>

                        <MenuItems
                          //  className="absolute bg-white w-full max-phone:w-[90vw] min-h-fit max-h-[50vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                          className="absolute bg-white w-full  overflow-y-auto left-0  origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                        >
                          <div className="py-1 w-full flex flex-col h-52 overflow-y-auto  ">
                            {sessionType?.map(session => (
                              <MenuItem key={session.id}>
                                <button
                                  value={session.value}
                                // onClick={() => handleFilterSelect(session)}
                                // className={`block px-6 flex-shrink-0  whitespace-nowrap text-sm text-gray-700 w-full ${
                                //     filtercontent === session.value ? 'bg-[#1976d2] text-white' : ''
                                // }`}
                                >
                                  <span className="flex typebtn py-2 px-4 items-center justify-start gap-4">
                                    <i className={session.displayClass}></i>
                                    <span>{session.name}</span>
                                  </span>
                                </button>
                              </MenuItem>
                            ))}
                          </div>
                        </MenuItems>
                      </Menu>
                    </div>

                    <div className='flex flex-col gap-4 max-mid:justify-between max-mid:flex-row'>

                      <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>
                        <div className='flex items-center max-mid:items-start max-mid:flex-col max-mid:gap-2'>
                          <span className='w-32 '>Date</span>
                          <input
                            type='text'
                            value={moment(selectedDate).format('DD-MM-YYYY')}
                            readOnly
                            className='rounded-md  max-mid:ml-0 ml-2 px-1 border-2'
                          />
                        </div>

                        <div className='flex items-center max-mid:items-start max-mid:flex-col max-mid:gap-2'>
                          <span className='ml-4 max-mid:ml-0 mr-8 '>Time</span>
                          <input
                            type='time'
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                            className='rounded-md px-1  border-2'
                          />
                        </div>

                      </div>

                      <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>
                        <span className='w-28   mr-[5px]'>Day</span>
                        <select
                          className='w-20 rounded-md px-1 py-2 border-2'
                          value={day}
                          onChange={(e) => setDay(e.target.value)}
                        >
                          <option value="">1</option>
                        </select>

                        <span className='ml-6 max-mid:mx-0 mr-8'>Week</span>
                        <select
                          className='w-20 rounded-md px-1 py-2 border-2'
                          value={week}
                          onChange={(e) => setWeek(e.target.value)}
                        >
                          <option value="">1</option>
                        </select>
                      </div>

                      <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>
                        <span className='w-24  mr-[15px]'>Duration<span className='text-red-600'>*</span></span>
                        <input
                          type='number'
                          value={durationTime}
                          onChange={handleChange}
                          className='min-w-24 rounded-md px-1 py-2 border-2'
                          placeholder='Time'
                          min='0'
                          step='1'
                        />
                        <select
                          className=' rounded-md px-1 py-2 border-2'
                          value={durationUnit}
                          onChange={(e) => setDurationUnit(e.target.value)}
                        >
                          <option value='min'>Min</option>
                          <option value='hr'>Hr</option>
                        </select>
                      </div>

                    </div>


                    <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
                      <span className='w-36  mr-[9px]'>Subject<span className='text-red-600'>*</span></span>
                      <input
                        type='text'
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}

                        className='w-full rounded-md px-1 py-2 border-2'
                      />
                    </div>

                    <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
                      <span className='w-40 '>Description<span className='text-red-600'>*</span></span>
                      <input
                        type='text'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className='w-full rounded-md px-1 py-2 border-2'
                      />
                    </div>

                    <div className='flex w-[45vw] max-mid:w-[65vw] max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center'>
                      <span className='w-40 '>Faculty</span>
                      <Menu as="div" className="relative w-full inline-block text-left">
                        <div className="w-full ">
                          <MenuButton
                            className="inline-flex h-11 w-full items-center justify-between pl-4 pr-1 relative gap-x-1.5 overflow-hidden rounded-md bg-white py-2 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
                          >
                            {(() => {
                              const session = sessionType?.find(item => item.value === filtercontent);

                              if (session) {
                                return (
                                  <>
                                    <span className="flex items-center  justify-start gap-4">
                                      {session.Logo}
                                      <span>{session.title}</span>
                                    </span>
                                  </>
                                );
                              } else {
                                return 'Select Content';
                              }
                            })()}
                            <ChevronDownIcon
                              aria-hidden="true"
                              className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
                            />
                          </MenuButton>
                        </div>

                        <MenuItems
                          //  className="absolute bg-white w-full max-phone:w-[90vw] min-h-fit max-h-[50vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                          className="absolute bg-white w-full  overflow-y-auto left-0  origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                        >
                          <div className="py-1 w-full flex flex-col h-52 overflow-y-auto  ">
                            {allfaculty?.items?.map(session => (
                              <MenuItem key={session.id}>
                                <button
                                  value={session.value}
                                // onClick={() => handleFilterSelect(session)}
                                // className={`block px-6 flex-shrink-0  whitespace-nowrap text-sm text-gray-700 w-full ${
                                //     filtercontent === session.value ? 'bg-[#1976d2] text-white' : ''
                                // }`}
                                >
                                  <span className="flex typebtn flex-col py-2 px-4  gap-4">
                                    <span className='flex items-center gap-4'>
                                      <img className='h-14 w-14 object-cover rounded-[50%] ' src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${session.upload_photo_name}`} alt="" />
                                      {session.agencyname}
                                    </span>

                                    <span className='flex flex-col items-start gap-2'>
                                      <span className='text-[10px] flex items-center gap-1'>
                                        <HiPhone/>
                                        {session.ag_mobileno}
                                      </span>

                                      <span className='text-[10px] flex items-center gap-1'>
                                        <MdEmail/>
                                        {session.ag_email}
                                      
                                      </span>
                                    </span>

                                  </span>
                                </button>
                              </MenuItem>
                            ))}
                          </div>
                        </MenuItems>
                      </Menu>
                    </div>

                    <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
                      <span className='w-36  mr-[9px]'>Module<span className='text-red-600'>*</span></span>
                      <select
                        className='w-full rounded-md px-1  py-2 border-2'
                        value={module}
                        onChange={(e) => setModule(e.target.value)}
                      >
                        <option value="">Select Module</option>
                      </select>
                    </div>

                    <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
                      <span className='w-40 '>Tags</span>
                      <input
                        type='text'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className='w-full rounded-md px-1 py-2 border-2'
                      />
                    </div>



                  </div>

                  {/* <input
                  type='text'
                  value={eventTitle}
                  onChange={(e) => setEventTitle(e.target.value)}
                  placeholder='Enter event title'
                  className='border p-2 rounded w-full mt-2'
                  /> */}
                  <div className='w-full  flex items-center px-6 justify-between mt-4 border-t-2 pt-6'>

                    <button
                      // onClick={() => setShowModal(false)}
                      className='bg-[#CCCCCC] text-black px-4 py-2 rounded'
                      onClick={() => setShowModal(false)}

                    >
                      Close
                    </button>

                    <button
                      // onClick={saveEvent}
                      className='bg-[#428BCA] text-white px-4 py-2 rounded '
                    >
                      Create Session
                    </button>
                  </div>
                </div>
              </Draggable>
            </div>
  )
}

export default CreateSessionFromCalendar