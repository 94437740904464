import React from 'react'
import TestNavbar from '../components/teastpage/TestNavbar'
import TestListTable from '../componentHome/test-list/TestList'

const TestListPage = () => {
    return (
        <>
            <TestNavbar>
                <TestListTable></TestListTable>
            </TestNavbar>
        </>
    )
}

export default TestListPage