import React from 'react'
import TestNavbar from '../components/teastpage/TestNavbar'
import FeedbackList from '../componentHome/tranning/Feedback'

const FeedbackPage = () => {
  return (
   <>
   <TestNavbar>
    <FeedbackList>
        
    </FeedbackList>
   </TestNavbar>
   </>
  )
}

export default FeedbackPage