import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom"; // Import useNavigate for redirection in React Router v6
import Breadcrumb from "../../componentHome/commonComponent/Breadcrumbs";
import TrainingsDashboard from "../Content/TrainingsDashboard";
const MainpainalPage = () => {
  const id = "74adfa71-1a47-5eda-4cbb-fd3d84749aa6"
  // const id = "3D34E43B-A021-6E1E-2E0A-10B4A8E4EF19"
  // const { id } = useParams()
  const navigate = useNavigate(); // Use useNavigate for navigation in React Router v6
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem('user'));
//main panel
  console.log(user)
  useEffect(() => {
    // Check if user data exists
    if (user) {
      setLoading(false); // Set loading to false if user data is already available
    } else {

// Simulate loading for 5 seconds
      const timer = setTimeout(() => {
        setLoading(false); // After 5 seconds, set loading to false
        // Redirect to login page
        navigate("/login-page"); // Replace '/login' with your actual login route
      },500);

      return () => clearTimeout(timer); // Clean up timer on component unmount
    }
  }, [user, navigate]);

  if (loading) {
    return <p> <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50">
      <div className="animate-spin rounded-full h-20 w-20 border-t-4 border-b-4 border-gray-900"></div>
    </div></p>; // Display loading message while waiting for user data
  }
  const paths = [
    { title: 'Home', url: '/' },
    { title: 'Dashboard', url: '/dashboard' }
  ];
  return (
    <>
      <div className="mainwrapperreact">
         <Breadcrumb paths={paths}/>
          <div className="p-6">
            {/* <NavLink to={`/Litteraroom`}>Littera Room </NavLink> */}
            <NavLink to={`/Litteraroom/session/${id}`}>Littera Room </NavLink>
            {/* <NavLink className={"hidden"} to={`/Litteraroom/session/${id}`}>Dynamic Littera Room </NavLink> */}
            <TrainingsDashboard/>
        </div>
      </div>
    </>
  );
};

export default MainpainalPage;
