import React, { useState, useEffect, useContext } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useConfig } from '../../ConfigContext';
import hex_md5 from "md5";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

import { validateMobileNumber, validatePassword } from '../utils/Validation'; // Adjust the path as per your file structure
import { TranslationContext } from '../../TranslationContext';

const PasswordResetModal = ({ isOpen, onClose }) => {
    const config = useConfig();
    const { translations } = useContext(TranslationContext);
    const [username, setUsername] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [mobileError, setMobileError] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otpLoading, setOtpLoading] = useState(false);
    const [passwordFieldsVisible, setPasswordFieldsVisible] = useState(false);
    const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [userid, setUserid] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [resendTimeout, setResendTimeout] = useState(0);


    const generateRandomPassword = (length) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const specials = "@#_";

        // Generate a random index for placing a special character
        const specialIndex = Math.floor(Math.random() * length);

        let password = "";
        for (let i = 0; i < length; i++) {
            if (i === specialIndex) {
                // Insert a random special character
                password += specials.charAt(Math.floor(Math.random() * specials.length));
            } else {
                // Insert a random alphanumeric character
                password += characters.charAt(Math.floor(Math.random() * characters.length));
            }
        }

        return password;
    };

    const handleGeneratePassword = () => {
        const generatedPassword = generateRandomPassword(10); // Generate an 8-character password
        setPassword(generatedPassword);
        setConfirmPassword(generatedPassword);
        setPasswordsMatch(true); // Assuming passwords match after generation
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        if (resendTimeout > 0 && otpSent) {
            const timer = setTimeout(() => {
                setResendTimeout(resendTimeout - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [resendTimeout, otpSent]);

    const handleInputChange = (e) => {
        setUsername(e.target.value);
    };

    const generateSalt = (length) => {
        const characters = "QWERTYUIOPLKJHGFDSAZCXCVBNMzaqwsxcderfvtgbyhnujmikolp0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleSendOTP = async () => {
        setOtpLoading(true);

        try {
            const response = await axios.get(
                `${config.REACT_APP_API_URL}/GenerateOTP?username=${username}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        APIKey: config.REACT_APP_API_KEY,
                    }
                }
            );
            setUserid(response.data.userid);
            console.log(response);
            toast.success(translations["OTP_sent_successfully"] || "OTP sent successfully!");

            setOtpSent(true);
            setResendTimeout(30); // Set the initial countdown time to 30 seconds

        } catch (error) {
            console.error("Error fetching OTP:", error);
            toast.error(translations["Failed_to_fetch_OTP"] || "Failed to fetch OTP. Please try again.");


        } finally {
            setOtpLoading(false);
        }
    };

    const handleOtpInputChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSubmitUpdatePassword = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.get(
                `${config.REACT_APP_API_URL}/VerifyOTP?username=${username}&otp=${otp}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        APIKey: config.REACT_APP_API_KEY,
                    }
                }
            );

            console.log(response.data); // Log the response for verification

            toast.success(translations["OTP_verified_successfully"] || "OTP verified successfully!");
            setPasswordFieldsVisible(true);

        } catch (error) {
            console.error("Error verifying OTP:", error);
            toast.error(translations["Failed_to_verify_OTP"] || "Failed to verify OTP. Please try again.");
        }
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        if (newPassword.length < 8 || newPassword !== confirmPassword || confirmPassword !== newPassword) {
            setPasswordsMatch(false);
        } else {
            setPasswordsMatch(true);
        }
    };


    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordsMatch(newConfirmPassword === password);
    };



    const handleUpdatePassword = async (e) => {
        e.preventDefault();
        setPasswordUpdateLoading(true);

        try {
            if (!validatePassword(password)) {
                // throw new Error(translations["Password_requirements_not_met"] || "Password must be at least 8 characters long and contain at least special character.");
            }

            // Generate salt
            const salt = generateSalt(8);

            // Hash the password with salt
            let hashedPassword = hex_md5(password).toString();
            //hashedPassword = hex_md5(salt.toString() + hashedPassword.toString());

            // Send hashedPassword and username to API for updating
            const headers = {
                "Content-Type": "application/json",
                APIKey: config.REACT_APP_API_KEY,
            };
            const response = await axios.post(
                `${config.REACT_APP_API_URL}/UpdatePassword`,
                {
                    userid: userid,
                    password: hashedPassword,
                    salt: salt, // Include salt in the request if necessary
                },
                {
                    headers
                }
            );

            console.log(response.data); // Log the response for verification

            toast.success(translations["Password_updated_successfully"] || "Password updated successfully!");
            onClose(); // Close modal after successful password update
            resetForm();
        } catch (error) {
            console.error("Error updating password:", error.message);
            toast.error(error.message);
        } finally {
            setPasswordUpdateLoading(false);
        }
    };
    const resetForm = () => {
        setUsername('');
        setOtp('');
        setOtpSent(false);
        setMobileError('');
        setPassword('');
        setConfirmPassword('');
        setPasswordFieldsVisible(false);
        setPasswordsMatch(true);
        setUserid('');
        setShowPassword(false);
        setShowConfirmPassword(false);
        setResendTimeout(0);
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    padding: "20px",
                    maxWidth: 400,
                    width: '60%',
                    maxHeight: 600,
                    overflowY: 'auto',
                    outline: 'none',
                    borderRadius: "12px",
                    '@media (max-width:600px)': { // Apply styles for screens narrower than 600px
                        width: '90%', // Adjust width as needed
                    }
                }}
            >
                <Typography variant="h5" component="h2" id="modal-title" sx={{ mb: 2 }}>
                    {translations["Forget_Password" || ["Forget Password"]]}
                </Typography>
                <Button
                    onClick={onClose}
                    color="inherit"
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }}
                >
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </Button>
                <form onSubmit={!passwordFieldsVisible ? handleSubmitUpdatePassword : handleUpdatePassword}>

                    <Box sx={{ mt: 2 }}>
                        <TextField
                            id="username"
                            name="username"
                            label={translations["Enter your User Name/Emailid/Mobile No." || ["Enter your User Name/Emailid/Mobile No."]]}
                            variant="outlined"
                            fullWidth
                            value={username}
                            onChange={handleInputChange}
                            error={mobileError !== ''}
                            helperText={mobileError}
                            required
                            InputProps={{
                                style: { fontSize: "15px" }  // Adjust the input font size here
                            }}
                            InputLabelProps={{
                                style: { fontSize: "13px" }  // Adjust the label font size here
                            }}
                            title="Please enter your Username"
                        />
                        <p className="text-red-500  mt-1 ml-2 " style={{ fontSize: "12px" }}>{translations["Mobile No. Shoud be contains country"] || ["Mobile number should contain country code (Ex: 91-7995xxxx)"]}</p>
                    </Box>


                    {!otpSent ? (
                        <Box sx={{ mt: 2, textAlign: 'right' }}>
                            <Button variant="contained" color="primary" onClick={handleSendOTP} disabled={otpLoading} style={{backgroundColor:"#428bca"}}>
                                {otpLoading ? "Sending OTP..." : translations["Send_OTP"] || "Send OTP"}
                            </Button>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                <Button variant="contained" color="primary" onClick={handleSendOTP} disabled={otpLoading || resendTimeout > 0} style={{backgroundColor:"#428bca"}}>
                                    {otpLoading ? "Sending OTP..." : (resendTimeout > 0 ? `Re-Send OTP (${resendTimeout})` : translations["ReSend_OTP"] || "ReSend OTP")}
                                </Button>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    id="otp"
                                    name="otp"
                                    label={translations["EnterOTP"] || ["Enter OTP"]}
                                    variant="outlined"
                                    fullWidth
                                    value={otp}
                                    onChange={handleOtpInputChange}
                                    required
                                    InputProps={{
                                        style: { fontSize: "15px" }  // Adjust the input font size here
                                    }}
                                    InputLabelProps={{
                                        style: { fontSize: "13px" }  // Adjust the label font size here
                                    }}
                                    title="Please enter OTP"
                                />
                            </Box>
                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                <Button type="submit" variant="contained" color="primary">
                                    {translations["verify_OTP"] || ["verify OTP"]}
                                </Button>
                            </Box>
                        </>
                    )}
                    {passwordFieldsVisible && (
                        <>
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label={translations['New_password'] || ["New password"]}
                                    variant="outlined"
                                    fullWidth
                                    helperText={!validatePassword(password) ? "Password must be at least 8 characters long and contain at least one special character" : ""}
                                    error={!validatePassword(password)}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        style: { fontSize: "13px" }  // Adjust the label font size here
                                    }}
                                    title="Please enter your new password"
                                />
                            </Box>
                          
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    label={translations['Confirm_new_password'] || ["Confirm New Password"]}
                                    variant="outlined"
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    required
                                    error={!passwordsMatch}
                                    helperText={!passwordsMatch ? "Passwords do not match" : ""}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        style: { fontSize: "13px" }  // Adjust the label font size here
                                    }}
                                    title="Please enter your Confirm New Password"
                                />
                            </Box>
                            <Box sx={{ mt: 2, textAlign: 'right' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!passwordsMatch || password.length < 8 || passwordUpdateLoading}style={{backgroundColor:"#428bca"}}
                                >
                                    {passwordUpdateLoading ? "Updating Password..." : translations["Update_Password"] || "Update_Password"}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleGeneratePassword}
                                    sx={{ mt: 1, ml: 1 }}
                                    style={{backgroundColor:"#428bca"}} >
                                    Generate Password
                                </Button>
                            </Box>
                        </>
                    )}
                </form>
            </Box>
        </Modal>
    );
};

export default PasswordResetModal;
