import { Box, Button,Typography } from '@mui/material'
import React from 'react'
const TestSubmit = () => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundColor: "#f9f9f9",

        }}>
            <Box sx={{ textAlign: "center", mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={require("../../../src/assest/logo_pra1.png")}
                    alt='logo'
                    style={{ width: '66px' }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '2rem', // Adjust the font size as needed
                    }}
                >
                    Pratham Softcon Private Limited
                </Typography>
                <Typography variant="body2" style={{ fontSize: 'medium'}}>
                    9 Amltash II, Chuna Bhatti Kolar Road <br />
                    Bhopal 462016
                </Typography>
            </Box>
            <Box sx={{
                width: "88%",
                backgroundColor: "#d9edf7",
                p: 4,
                borderRadius: 2,
                boxShadow: 2,
                textAlign: 'center',
            }}>
                <Typography variant="h6" color="#31708f" style={{ fontSize:"medium"}}> Thank you for submitting your answers</Typography>

            </Box>

        </Box>
    )
}

export default TestSubmit