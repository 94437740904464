import React from "react";
import NavbarHome from "../componentHome/navbar/Navbar";
import Home from "../componentHome/home/Home";
import Header from "../componentHome/header/Header";

const HomePage = () => {
  return (
    <>
    
     <Home></Home>
    </>
  );
};

export default HomePage;
