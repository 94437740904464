import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { Modal, Button, List, Divider, Input } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { TranslationContext } from "../../TranslationContext";
import { useConfig } from '../../ConfigContext';

const { Search } = Input;

const JobRoleModal = ({ isOpen, onClose }) => {
    const config = useConfig();
    const { translations } = useContext(TranslationContext);
    const [leftItems, setLeftItems] = useState([]);
    const [rightItems, setRightItems] = useState([]);
    const [searchText, setSearchText] = useState('');
    
    // Determine screen width
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (config && config.REACT_APP_API_URL && config.REACT_APP_API_KEY) {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${config.REACT_APP_API_URL}/GetActivity`, {
                        headers: {
                            "Content-Type": "application/json",
                            APIKey: config.REACT_APP_API_KEY,
                        },
                    });

                    if (response.data && Array.isArray(response.data.items)) {
                        const names = response.data.items.map(item => item.ttca_name);
                        setLeftItems(names);
                        console.log('API Response:', names);
                    }
                } catch (error) {
                    console.error("There was an error fetching the data!", error);
                }
            };

            fetchData();
        }
    }, [config]);

    const handleAdd = (item) => {
        setLeftItems(leftItems.filter((i) => i !== item));
        setRightItems([...rightItems, item]);
    };

    const handleRemove = (item) => {
        setRightItems(rightItems.filter((i) => i !== item));
        setLeftItems([...leftItems, item]);
    };

    const handleAddMore = () => {
        const newItem = `Position ${leftItems.length + rightItems.length + 1}`;
        setLeftItems([...leftItems, newItem]);
    };

    const handleSave = () => {
        alert("Saved!");
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value.toLowerCase());
    };

    const filteredLeftItems = leftItems.filter(item => item.toLowerCase().includes(searchText));

    // Inline styles
    const modalStyle = {
        top: 20,
        width: isMobile ? '95%' : '90%',
        maxWidth: isMobile ? '360px' : '1200px',
        margin: isMobile ? 'auto' : 'initial',
    };

    const bodyStyle = {
        padding: 0,
        height: isMobile ? 'calc(100vh - 100px)' : '500px',
        overflowY: 'auto',
    };

    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            title={translations["Activities"] || "Activities"}
            footer={null}
            style={modalStyle}
            bodyStyle={bodyStyle}
            centered
        >
            <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <div style={{ flex: 1, padding: '10px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={handleAddMore}
                            >
                                Add More
                            </Button>
                        </div>
                    </div>
                    <Divider />
                    <Search
                        placeholder="Search positions"
                        onChange={handleSearch}
                        style={{ marginTop: '10px' }}
                    />
                    <List
                        dataSource={filteredLeftItems}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <Button key="add" onClick={() => handleAdd(item)} icon={<PlusOutlined />} />
                                ]}
                            >
                                {item}
                            </List.Item>
                        )}
                    />
                </div>
                <Divider type="vertical" />
                <div style={{ flex: 1, padding: '10px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button
                            type="primary"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </div>
                    <Divider />
                    <List
                        dataSource={rightItems}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <Button key="remove" onClick={() => handleRemove(item)} icon={<MinusOutlined />} />
                                ]}
                            >
                                {item}
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default JobRoleModal;
