// ThemeProvider.js

import React, { createContext, useState, useContext } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';

const ThemeContext = createContext();

const blueTheme = createTheme({
  palette: {
    primary: {
      main: '#428bca',
    },
    background: {
      default: '#428bca',
    },
    text: {
      primary: '#000000',
    },
  },
});

const redTheme = createTheme({
  palette: {
    primary: {
      main: '#d32f2f',
    },
    background: {
      default: '#bbdefb',
    },
    text: {
      primary: '#934343',
    },
  },
});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(blueTheme);

  const toggleTheme = (themeName) => {
    setTheme(themeName === "#428bca" ? blueTheme : redTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <MUIThemeProvider theme={theme}>
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
