import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Box, IconButton, Button, Paper, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import "./fromcom.css";
import { useConfig } from '../../ConfigContext';

const FileUpload = forwardRef(({ onFileChange, onFileDelete, initialFileUrl }, ref) => {
  const config = useConfig();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const fileInputRef = useRef(null); // Create a ref for the file input

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const filePreviewUrl = URL.createObjectURL(file);
      setSelectedFile(file);
      setPreviewUrl(filePreviewUrl);
      if (onFileChange) {
        onFileChange(file);
      }
    }
  };

  // Handle file deletion
  const handleDeleteFile = () => {
    if (selectedFile) {
      URL.revokeObjectURL(previewUrl);
      setSelectedFile(null);
      setPreviewUrl('');
      if (onFileDelete) {
        onFileDelete(); // Notify parent component of file deletion
      }
    }
  };

  // Use React useImperativeHandle to expose methods to parent component
  useImperativeHandle(ref, () => ({
    cancelUpload() {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setSelectedFile(null);
      setPreviewUrl('');
      if (onFileDelete) {
        onFileDelete(); // Notify parent component to clear file data
      }
    }
  }));

  useEffect(() => {
    // Update preview URL if initialFileUrl changes
    if (initialFileUrl) {
      setPreviewUrl(`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_FUNCTION_IMG_PATH}/${initialFileUrl}`);
    }
  }, [initialFileUrl, config]);

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          minHeight: 110,
          border: '2px dashed #0056b3',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
        }}
        component="label"
      >
        <input
          type="file"
          id="file-upload"
          ref={fileInputRef} // Attach ref to file input
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <label htmlFor="file-upload">
          <IconButton component="span" color="primary" size="large" aria-label="Upload file">
            <UploadIcon  className='upload-icon'/>
          </IconButton>
        </label>
        
        <Typography variant="caption">Upload Document</Typography>

        {previewUrl && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 0,
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              }}
              onClick={handleDeleteFile}
              aria-label="Delete file"
            >
              <DeleteIcon />
            </IconButton>
            <img
              src={previewUrl}
              alt="Selected file preview"
              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px' }}
            />
          </Box>
        )}
      </Paper>
      {/* <Box sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            if (ref.current) {
              ref.current.cancelUpload();
            }
          }}
          disabled={!selectedFile && !previewUrl}
        >
          Cancel
        </Button>
      </Box> */}
    </Box>
  );
});

export default FileUpload;
