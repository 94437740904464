import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';

const DraggableModal = ({ open, handleClose }) => {
    const [dragging, setDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [initialOffset, setInitialOffset] = useState({ x: 0, y: 0 });

    useEffect(() => {
        // Center the modal on initial load
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;
        setPosition({ x: centerX, y: centerY });
    }, []);

    const handleMouseDown = (e) => {
        setDragging(true);
        setInitialOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            setPosition({
                x: e.clientX - initialOffset.x,
                y: e.clientY - initialOffset.y
            });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const modalStyle = {
        display: open ? 'block' : 'none', // Toggle display based on open state
        position: 'absolute',
        top: position.y,
        left: position.x,
        transform: 'translate(-50%, -50%)', // Center the modal properly
        border: '2px solid #428bca',
        backgroundColor: '#fff',
        width: "500px",
        cursor: 'move',
        zIndex: 1000,
    };
    const modalContent = {

        top: '0px',
        left: '0px',
    };

    return (
        <>
        <div
            className="draggable-modal"
            style={modalStyle}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            <div className="modal-content" id="dvaboutlitteraheader">
                <div className="modal-header">
                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button" onClick={handleClose}>×</button>
                    <h4 className="modal-title" style={{padding:"5px"}}><span data-tag="About_littera">About littera</span></h4>
                </div>
                <div className="modal-body" style={{paddingLeft:"10px"}}>
                    
                    <span data-tag="Last_updated_on">Last Updated On</span> : 14-May-2024
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal" data-tag="Close" onClick={handleClose}>Close</button>
                </div>
            </div>
        </div>
        </>
    );
};

export default DraggableModal;
