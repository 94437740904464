import React, { useState, useEffect } from "react";
import TestNavbar from "../components/teastpage/TestNavbar";
import FuncationAnt from "../componentHome/fromtab/FuncationAnt";

const FuncationAntPage = () => {
  return ( 
    <>
      <TestNavbar>
     <FuncationAnt />
      </TestNavbar>
    </>
  );
};

export default FuncationAntPage;
