import React from 'react'

import TestNavbar from '../components/teastpage/TestNavbar'
import ActivityAnt from '../componentHome/fromtab/ActivityAnt'

const AddActivitesPage = () => {
  return (
    <>
      <TestNavbar>
        <ActivityAnt></ActivityAnt>
      </TestNavbar>

    </>
  )
}

export default AddActivitesPage