import React from "react";
import Navbar from "../navbar/Navbar";
import Header from "../header/Header";
import EventSection from "./EventSection";
import AboutUs from "./AboutUs";
import Achivment from "./Achivement";
import Contact from "./Contact";
import Footer from "../footer/Footer";

const Home = () => {
  return (
    <>
      <Navbar />

    </>
  );
};

export default Home;
