import React, { useEffect, useRef } from 'react';
import { getDocument } from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry.js';
import { useConfig } from '../../ConfigContext';


const PdfViewer = ({ pdf }) => {
  const config = useConfig()
  const containerRef = useRef();
  const isRendering = useRef(false);
  useEffect(() => {
    if (isRendering.current) return;
    const renderPdf = async () => {
      const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
      if (!url) return;
      isRendering.current = true;
      containerRef.current.innerHTML = '';
      try {
        const pdf = await getDocument(url).promise;
        console.log(`PDF loaded: ${pdf.numPages} pages`);
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
          console.log(`Rendering page ${pageNumber}`);
          const viewport = page.getViewport({ scale: 1.1 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
          containerRef.current.appendChild(canvas);
        }
      } catch (error) {
        console.error('Error rendering PDF', error);
      } finally {
          isRendering.current = false;
      }
    };
    renderPdf();
  }, [pdf]);
  return (
    <div className='h-full w-full flex justify-center'>
      <div className='h-full w-full' ref={containerRef}></div>
    </div>
  );
};
export default PdfViewer;


// import React, { useEffect, useRef } from 'react';

// //import { GlobalWorkerOptions } from 'pdfjs-dist';
// // import 'pdfjs-dist/build/pdf.worker.entry';
// //import { getDocument } from 'pdfjs-dist';
// // import 'pdfjs-dist/build/pdf.worker.entry.js';

// import { useConfig } from '../../ConfigContext';


// const PdfViewer = ({ pdf }) => {


  
//   const config = useConfig()
//   const containerRef = useRef();
//   const isRendering = useRef(false);
//   // useEffect(() => {
//   //   if (isRendering.current) return;
//   //   const renderPdf = async () => {
//   //     const url = `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFilePath}`;
//   //     if (!url) return;
//   //     isRendering.current = true;
//   //     containerRef.current.innerHTML = '';
//   //     try {
//   //       const pdf = await getDocument(url).promise;
//   //       console.log(`PDF loaded: ${pdf.numPages} pages`);
//   //       for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
//   //         const page = await pdf.getPage(pageNumber);
//   //         console.log(`Rendering page ${pageNumber}`);
//   //         const viewport = page.getViewport({ scale: 1.1 });
//   //         const canvas = document.createElement('canvas');
//   //         const context = canvas.getContext('2d');
//   //         canvas.width = viewport.width;
//   //         canvas.height = viewport.height;
//   //         const renderContext = {
//   //           canvasContext: context,
//   //           viewport: viewport,
//   //         };
//   //         await page.render(renderContext).promise;
//   //         containerRef.current.appendChild(canvas);
//   //       }
//   //     } catch (error) {
//   //       console.error('Error rendering PDF', error);
//   //     } finally {
//   //         isRendering.current = false;
//   //     }
//   //   };
//   //   renderPdf();
//   // }, [pdf]);
//   return (
//     <div className='h-full w-full flex justify-center'>
//       <div className='h-full w-full '>
//         <iframe className='h-full w-full' src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}/${pdf[0]?.globalFileName}`} frameborder="0"></iframe>
//       </div>
//     </div>
//   );
// };
// export default PdfViewer;
