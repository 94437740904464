import React, { useState, usee } from 'react'
import TestNavbar from '../components/teastpage/TestNavbar'
import JobPositionAnt from '../componentHome/fromtab/JobPositionAnt'

const JobPositionAntPage = () => {
  

  return (
    <TestNavbar>

      <JobPositionAnt />

    </TestNavbar>
  )
}

export default JobPositionAntPage