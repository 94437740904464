import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, CardContent, CardMedia, Typography, Button } from "@mui/material";

const EventSection = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3, // Show 3 slides initially
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show 2 slides for medium screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 slide for small screens
        },
      },
    ],
  };

  const cardStyle = {
    // maxWidth: 345,
    // margin: "5px auto 30px", // 5px margin top and bottom, auto margin left and right
    backgroundColor: "#fff", // Blue background color
    color: "black", // White text color
    marginLeft: "10px"
  };

  return (
    <div id="Events" className="main-container" style={{ width: "90%", margin: "auto" }}>
      <div className="text" style={{ marginTop: "43px", marginBottom: "43px" }}>
        <p className="text-blk headingText" style={{ textAlign: "center", fontSize: "2.5rem" }}>EVENTS</p>
        <p
          className="paragraph-txt text-center"
          style={{ fontSize: "23px", fontFamily: "none" }}
        >
          These are upcoming events
        </p>
      </div>

      <div id="carouselExampleSlidesOnly">
        <div
          className="carousel-inner"
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            // marginLeft: "14px",
            margin: "10px"
          }}
        >
          <Slider {...settings}>
            <div className="carousel-control11">
              <Card style={cardStyle} >
                <CardMedia
                  style={{ height: 140, padding: "10px" }}
                  image={require("../../assest/card_image-1.jpg")} // Replace with your image path
                  title="Event Image"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" style={{ fontSize: "18px" }}>
                    Noteworthy technology acquisitions 2021
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ marginBottom: 10, fontSize: "15px" }}>
                    Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
                  </Typography>
                  <Button
                    style={{ marginTop: 10, cursor: "pointer" }}
                    variant="contained"
                    href="https://dev.littera.in/frm_read_course_details.aspx?q=00d3aafd-17ba-7714-04cf-c9c977704079"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Register Now
                  </Button>
                </CardContent>
              </Card>
            </div>
            <div className="carousel-control11">
              <Card style={cardStyle}>
                <CardMedia
                  style={{ height: 140, padding: "10px" }}
                  image={require("../../assest/card_image-1.jpg")} // Replace with your image path
                  title="Event Image"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" style={{ fontSize: "18px" }}>
                    Noteworthy technology acquisitions 2021
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ marginBottom: 10, fontSize: "15px" }}>
                    Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
                  </Typography>
                  <Button
                    style={{ marginTop: 10, cursor: "pointer" }}
                    variant="contained"
                    href="https://dev.littera.in/frm_read_course_details.aspx?q=00d3aafd-17ba-7714-04cf-c9c977704079"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Register Now
                  </Button>
                </CardContent>
              </Card>
            </div>
            <div className="carousel-control11">
              <Card style={cardStyle}>
                <CardMedia
                  style={{ height: 140, padding: "10px" }}
                  image={require("../../assest/card_image-1.jpg")} // Replace with your image path
                  title="Event Image"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" style={{ fontSize: "18px" }}>
                    Noteworthy technology acquisitions 2021
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ marginBottom: 10,fontSize: "15px" }}>
                    Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.
                  </Typography>
                  <Button
                    style={{ marginTop: 10, cursor: "pointer" }}
                    variant="contained"
                    href="https://dev.littera.in/frm_read_course_details.aspx?q=00d3aafd-17ba-7714-04cf-c9c977704079"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Register Now
                  </Button>
                </CardContent>
              </Card>
            </div>
          </Slider>
        </div>
      </div>

      <div className="button-container" style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
        <button className="inline-flex items-center px-3 py-2 text-lg font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          View All Courses
          <svg
            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default EventSection;
