import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box'; // Assuming you are using MUI for Box
import Typography from '@mui/material/Typography'; // Assuming you are using MUI for Typography
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const CountdownTimer = ({ initialTime }) => {
  const [time, setTime] = useState(initialTime);
  const navigate = useNavigate(); // Get the navigate function

  useEffect(() => {
    if (time <= 0) {
      // Navigate to the desired page when time expires
      navigate('/submit-test'); // Replace '/submit-page' with your desired route
      return;
    }

    const interval = setInterval(() => {
      setTime(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [time, navigate]);

  // Format time as HH:MM:SS
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

    return (
        <Box textAlign="center">
            <Typography variant="h4" color="error">
                {formatTime(time)}
            </Typography>
            <Typography variant="caption" color="textSecondary" style={{ fontSize: "medium" }}>
                HH:MM:SS
            </Typography>
        </Box>
    );
};

export default CountdownTimer;
