// import React, { useEffect, useState } from 'react'
// import { FaPlay, FaRegMessage } from 'react-icons/fa6'
// import { LiaStickyNoteSolid } from 'react-icons/lia'
// import videoArray from '../../Utils/VideoArray'
// import docs from '../../Utils/FilesArray'
// import htmlContent from '../../Utils/HtmlArray'
// import { useFeedback } from '../../Context/FeedbackContext'
// import { useConfig } from '../../ConfigContext'


// function isImageFile(fileName) {
//     const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg'];
//     const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
//     return imageExtensions.includes(fileExtension.toLowerCase());
// }

// const AllContent = ({ onFileSelect , onHtmlSelect , showComments , showNotes}) => {
//     const config = useConfig()
//     const { 
//         selectedSessionContent,
//         Video,
//         Audio,
//         pd
//         WYISIWYG,
//         Image,
 
//     } = useFeedback()
    
//     const fileSelectHandler = (file)=>{
//         onFileSelect(file)
//     } 

//     const htmlFileSelectHandler = (file)=>{
//         onHtmlSelect(file)
//     } 

//     const showCommentHandler = (data)=>{
//         showComments(data)
//     } 

//     const showNotestHandler = (data)=>{
//         showNotes(data)
//     } 

//     return (
//     <div className='px-2 py-2 max-md:h-fit max-md:flex-row max-md:w-full max-md:gap-2  datadiv flex flex-col items-center w-full border-b-2 gap-4 overflow-y-auto'>
//         <div className='h-full w-full overflow-y-auto flex flex-col gap-2'>
           
//             {
//                 Video?.length > 0 ?            
//                     <div className='max-md:flex-row max-md:min-w-fit w-full flex flex-col items-center gap-2 border-2 p-2'>
//                         <h4 className='uppercase  max-md:px-4'>{Video?.length === 1 ? "Video" : "Videos"  }</h4>

            //             {
            //                 Video?.map((item,index)=>(
            //                     <div key={index} className='videodivv max-md:w-56 relative h-40 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
            //                          {
            //                             (item.globalthumbnailPath === "jpg" || item.globalthumbnailPath === "png" || item.globalthumbnailPath === "Image") ?
            //                             <img 
            //                                 src={`${config?.LITTERA_CDN_BASE_URL}${config?.LITTERA_CONTENT_PATH}${item.globalFilePath}`} 
            //                                 className='blur-[2px] relative h-full w-full object-cover' 
            //                                 alt="" 
            //                             /> 
            //                             :
            //                             null
            //                         }
            //                         <div className='absolute flex h-full items-center justify-between w-full px-2'>
            //                             <h5 className=' text-black w-[45%] '>
            //                                 {/* {item.ttsad_title} */}
            //                                 {item.ttsad_title.split(' ').slice(0, 3).join(' ')}
            //                                 {item.ttsad_title.split(' ').length > 3 ? '...' : ''}
            //                             </h5>
            //                             <div className=' flex h-full items-center w-[50%] justify-between'>
            //                                 <FaPlay onClick={()=>fileSelectHandler(item)} cursor={"pointer"} fontSize={26}  color='black' />
            //                                 <div className='h-full w-10  flex flex-col items-center justify-center gap-4'>
            //                                     <FaRegMessage onClick={()=>showCommentHandler(item)} style={{cursor:"pointer"}} opacity={.7} fontSize={17}  color='black' />
            //                                     <LiaStickyNoteSolid onClick={()=>showNotestHandler(item)} style={{cursor:"pointer"}} opacity={.9 } fontSize={22} color='black' />
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 ))
            //             }
            //         </div>
            //         :
            //         null
            // }
            
//             {
//                 pdf?.length > 0 ?            
//                     <div className='max-md:flex-row max-md:min-w-fit w-full flex flex-col items-center gap-2 border-2 p-2'>
//                         <h4 className='uppercase  max-md:px-4'>{pdf?.length === 1 ? "Pdf" : "Pdfs"  }</h4>

//                         {
//                             pdf?.map((item,index)=>(
//                                 <div key={index} className='videodivv max-md:w-56 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
//                                      {
//                                         (item.globalthumbnailPath === "jpg" || item.globalthumbnailPath === "png" || item.globalthumbnailPath === "Image") ?
//                                         <img 
//                                             src={`${config?.LITTERA_CDN_BASE_URL}${config?.LITTERA_CONTENT_PATH}${item.globalFilePath}`} 
//                                             className=' relative h-full w-full object-cover object-center' 
//                                             alt="" 
//                                         /> 
//                                         :
//                                         null
//                                     }
                                     
//                                     <div className='absolute flex h-full items-center justify-between w-full px-2'>
//                                         <h5 className=' text-black w-[45%] '>
//                                             {/* {item.ttsad_title} */}
//                                             {item.ttsad_title.split(' ').slice(0, 3).join(' ')}
//                                             {item.ttsad_title.split(' ').length > 3 ? '...' : ''}
//                                         </h5>
//                                         <div className=' flex h-full items-center w-[50%] justify-between'>
//                                             <FaPlay onClick={()=>fileSelectHandler(item)} cursor={"pointer"} fontSize={26}  color='black' />
//                                             <div className='h-full w-10  flex flex-col items-center justify-center gap-4'>
//                                                 <FaRegMessage onClick={()=>showCommentHandler(item)} style={{cursor:"pointer"}} opacity={.7} fontSize={17}  color='black' />
//                                                 <LiaStickyNoteSolid onClick={()=>showNotestHandler(item)} style={{cursor:"pointer"}} opacity={.9 } fontSize={22} color='black' />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))
//                         }
//                     </div>
//                     :
//                     null
//             }

//             {
//                 WYISIWYG?.length > 0 ?            
//                     <div className='max-md:flex-row max-md:min-w-fit w-full flex flex-col items-center gap-2 border-2 p-2'>
//                         <h4 className='uppercase  max-md:px-4'>{WYISIWYG?.length === 1 ? "WYISIWYG" : "WYISIWYGs"  }</h4>

//                         {
//                             WYISIWYG?.map((item,index)=>(
//                                 <div key={index} className='videodivv max-md:w-56 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
//                                      {
//                                         (item.globalthumbnailPath === "jpg" || item.globalthumbnailPath === "png" || item.globalthumbnailPath === "Image") ?
//                                         <img 
//                                             src={`${config?.LITTERA_CDN_BASE_URL}${config?.LITTERA_CONTENT_PATH}${item.globalFilePath}`} 
//                                             className=' relative h-full w-full object-cover object-center' 
//                                             alt="" 
//                                         /> 
//                                         :
//                                         null
//                                     } 
//                                     <div className='absolute flex h-full items-center justify-between w-full px-2'>
//                                         <h5 className=' text-black w-[45%] '>
//                                             {item.ttsad_title.split(' ').slice(0, 3).join(' ')}
//                                             {item.ttsad_title.split(' ').length > 3 ? '...' : ''}
//                                         </h5>
//                                         <div className=' flex h-full items-center w-[50%] justify-between'>
//                                             <FaPlay onClick={()=>htmlFileSelectHandler(item)} cursor={"pointer"} fontSize={26}  color='black' />
//                                             <div className='h-full w-10  flex flex-col items-center justify-center gap-4'>
//                                                 <FaRegMessage onClick={()=>showCommentHandler(item)} style={{cursor:"pointer"}} opacity={.7} fontSize={17}  color='black' />
//                                                 <LiaStickyNoteSolid onClick={()=>showNotestHandler(item)} style={{cursor:"pointer"}} opacity={.9 } fontSize={22} color='black' />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))
//                         }
//                     </div>
//                     :
//                     null
//             }

//             {
//                 Audio?.length > 0 ?            
//                     <div className='max-md:flex-row max-md:min-w-fit w-full flex flex-col items-center gap-2 border-2 p-2'>
//                         <h4 className='uppercase  max-md:px-4'>{Audio?.length === 1 ? "Audio" : "Audios"  } </h4>

//                         {
//                             Audio?.map((item,index)=>(
//                                 <div key={index} className='videodivv max-md:w-56 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
//                                      {
//                                         (item.globalthumbnailPath === "jpg" || item.globalthumbnailPath === "png" || item.globalthumbnailPath === "Image") ?
//                                         <img 
//                                             src={`${config?.LITTERA_CDN_BASE_URL}${config?.LITTERA_CONTENT_PATH}${item.globalFilePath}`} 
//                                             className=' relative h-full w-full object-cover object-center' 
//                                             alt="" 
//                                         /> 
//                                         :
//                                         null
//                                     } 
//                                     <div className='absolute flex h-full items-center justify-between w-full px-2'>
//                                         <h5 className=' text-black w-[45%] '>
//                                             {item.ttsad_title.split(' ').slice(0, 3).join(' ')}
//                                             {item.ttsad_title.split(' ').length > 3 ? '...' : ''}
//                                         </h5>
//                                         <div className=' flex h-full items-center w-[50%] justify-between'>
//                                             <FaPlay onClick={()=>fileSelectHandler(item)} cursor={"pointer"} fontSize={26}  color='black' />
//                                             <div className='h-full w-10  flex flex-col items-center justify-center gap-4'>
//                                                 <FaRegMessage onClick={()=>showCommentHandler(item)} style={{cursor:"pointer"}} opacity={.7} fontSize={17}  color='black' />
//                                                 <LiaStickyNoteSolid onClick={()=>showNotestHandler(item)} style={{cursor:"pointer"}} opacity={.9 } fontSize={22} color='black' />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))
//                         }
//                     </div>
//                     :
//                     null
//             }

//             {
//                 !(Audio.length || Image.length || Video.length || WYISIWYG.length || pdf.length) ? (
//                     <p className='w-full flex justify-center'>CONTENT NOT AVAILABLE...</p>
//                 ) : null
//             }

//         </div>
        
//     </div>
//   )
// }

// export default AllContent


// import React from 'react';
// import { FaPlay, FaRegMessage } from 'react-icons/fa6';
// import { LiaStickyNoteSolid } from 'react-icons/lia';
// import { useFeedback } from '../../Context/FeedbackContext';
// import { useConfig } from '../../ConfigContext';

// const AllContent = ({ onFileSelect, onHtmlSelect, showComments, showNotes }) => {
//     const config = useConfig();
//     const { Video, Audio, pdf, WYISIWYG, Image } = useFeedback();

//     const allContent = [
//         ...Video.map(item => ({ type: 'Video', data: item })),
//         ...Audio.map(item => ({ type: 'Audio', data: item })),
//         ...pdf.map(item => ({ type: 'Pdf', data: item })),
//         ...WYISIWYG.map(item => ({ type: 'WYISIWYG', data: item })),
//         ...Image.map(item => ({ type: 'Image', data: item }))
//     ];

//     const fileSelectHandler = (file) => {
//         onFileSelect(file);
//     };

//     const htmlFileSelectHandler = (file) => {
//         onHtmlSelect(file);
//     };

//     const showCommentHandler = (data) => {
//         showComments(data);
//     };

//     const showNotesHandler = (data) => {
//         showNotes(data);
//     };

//     return (
//         <div className='px-2 py-2 max-md:h-fit max-md:flex-row max-md:w-full max-md:gap-2 datadiv flex flex-col items-center w-full border-b-2 gap-4 overflow-y-auto'>
//             <div className='h-full w-full overflow-y-auto flex flex-col gap-2'>
//                 {allContent.length > 0 ? (
//                     allContent.map((item, index) => (
//                         <div key={index} className='max-md:flex-row max-md:min-w-fit w-full flex flex-col items-center gap-2 border-2 p-2'>
//                             <h4 className='uppercase max-md:px-4'>{item.type}s</h4>
//                             <div className='videodivv max-md:w-56 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
//                                 {(item.data.globalthumbnailPath === "jpg" || item.data.globalthumbnailPath === "png" || item.data.globalthumbnailPath === "Image") ?
//                                     <img
//                                         src={`${config?.LITTERA_CDN_BASE_URL}${config?.LITTERA_CONTENT_PATH}${item.data.globalFilePath}`}
//                                         className='relative h-full w-full object-cover object-center'
//                                         alt=""
//                                     />
//                                     : null
//                                 }
//                                 <div className='absolute flex h-full items-center justify-between w-full px-2'>
//                                     <h5 className='text-black w-[45%]'>
//                                         {item.data.ttsad_title.split(' ').slice(0, 3).join(' ')}
//                                         {item.data.ttsad_title.split(' ').length > 3 ? '...' : ''}
//                                     </h5>
//                                     <div className='flex h-full items-center w-[50%] justify-between'>
//                                         <FaPlay onClick={() => fileSelectHandler(item.data)} cursor={"pointer"} fontSize={26} color='black' />
//                                         <div className='h-full w-10 flex flex-col items-center justify-center gap-4'>
//                                             <FaRegMessage onClick={() => showCommentHandler(item.data)} style={{ cursor: "pointer" }} opacity={.7} fontSize={17} color='black' />
//                                             <LiaStickyNoteSolid onClick={() => showNotesHandler(item.data)} style={{ cursor: "pointer" }} opacity={.9} fontSize={22} color='black' />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     ))
//                 ) : (
//                         <p className='w-full flex justify-center'>CONTENT NOT AVAILABLE...</p>
//                     )}
//             </div>
//         </div>
//     );
// };

// export default AllContent;

// import React from 'react';
// import { FaPlay, FaRegMessage } from 'react-icons/fa6';
// import { LiaStickyNoteSolid } from 'react-icons/lia';
// import { useFeedback } from '../../Context/FeedbackContext';
// import { useConfig } from '../../ConfigContext';

// const AllContent = ({ onFileSelect, onHtmlSelect, showComments, showNotes }) => {
//     const config = useConfig();
//     const { Video, Audio, pdf, WYISIWYG, Image } = useFeedback();

//     const allContent = [
//         ...Video.map(item => ({ type: 'Video', data: item })),
//         ...Audio.map(item => ({ type: 'Audio', data: item })),
//         ...pdf.map(item => ({ type: 'Pdf', data: item })),
//         ...WYISIWYG.map(item => ({ type: 'WYISIWYG', data: item })),
//         ...Image.map(item => ({ type: 'Image', data: item })),
//     ];

//     const fileSelectHandler = (file) => {
//         onFileSelect(file);
//     };

//     const htmlFileSelectHandler = (file) => {
//         onHtmlSelect(file);
//     };

//     const showCommentHandler = (data) => {
//         showComments(data);
//     };

//     const showNotesHandler = (data) => {
//         showNotes(data);
//     };

//     // Helper function to render items of the same type grouped together
//     const renderGroupedContent = (type, items) => (
//         <div className='max-md:flex-row max-md:min-w-fit w-full flex flex-col items-center gap-2 border-2 p-2'>
//             <h4 className='uppercase max-md:px-4'>{items.length === 1 ? type : `${type}s`}</h4>
//             {items.map((item, index) => (
//                 <div key={index} className='videodivv max-md:w-56 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
//                     {(item.data.globalthumbnailPath === "jpg" || item.data.globalthumbnailPath === "png" || item.data.globalthumbnailPath === "Image") ?
//                         <img
//                             src={`${config?.LITTERA_CDN_BASE_URL}${config?.LITTERA_CONTENT_PATH}${item.data.globalFilePath}`}
//                             className='relative h-full w-full object-cover object-center'
//                             alt=""
//                         />
//                         : null
//                     }
//                     <div className='absolute flex h-full items-center justify-between w-full px-2'>
//                         <h5 className='text-black w-[45%]'>
//                             {item.data.ttsad_title.split(' ').slice(0, 3).join(' ')}
//                             {item.data.ttsad_title.split(' ').length > 3 ? '...' : ''}
//                         </h5>
//                         <div className='flex h-full items-center w-[50%] justify-between'>
//                             <FaPlay onClick={() => type === 'WYISIWYG' ? htmlFileSelectHandler(item.data) : fileSelectHandler(item.data)} cursor={"pointer"} fontSize={26} color='black' />
//                             <div className='h-full w-10 flex flex-col items-center justify-center gap-4'>
//                                 <FaRegMessage onClick={() => showCommentHandler(item.data)} style={{ cursor: "pointer" }} opacity={.7} fontSize={17} color='black' />
//                                 <LiaStickyNoteSolid onClick={() => showNotesHandler(item.data)} style={{ cursor: "pointer" }} opacity={.9} fontSize={22} color='black' />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             ))}
//         </div>
//     );

//     return (
//         <div className='px-2 py-2 max-md:h-fit max-md:flex-row max-md:w-full max-md:gap-2 datadiv flex flex-col items-center w-full border-b-2 gap-4 overflow-y-auto'>
//             <div className='h-full w-full overflow-y-auto flex flex-col gap-2'>
//                 {allContent.length > 0 ? (
//                     ['Video', 'Audio', 'Pdf', 'WYISIWYG', 'Image '].map(type => {
//                         const itemsOfType = allContent.filter(item => item.type === type);
//                         return itemsOfType.length > 0 ? renderGroupedContent(type, itemsOfType) : null;
//                     })
//                 ) : (
//                         <p className='w-full flex justify-center'>CONTENT NOT AVAILABLE...</p>
//                     )}
//             </div>
//         </div>
//     );
// };

// export default AllContent;


// import React from 'react';
// import { FaPlay, FaRegMessage } from 'react-icons/fa6';
// import { LiaStickyNoteSolid } from 'react-icons/lia';
// import { useFeedback } from '../../Context/FeedbackContext';
// import { useConfig } from '../../ConfigContext';
// import { Tooltip } from 'react-tooltip';

// const AllContent = ({ onFileSelect, onHtmlSelect, showComments, showNotes }) => {
//     const config = useConfig();
//     const { Video, Audio, pdf, WYISIWYG, Image, Cdn , filtercontent } = useFeedback();

//     const allContent = [
//         ...Video.map(item => ({ type: 'Video', data: item })),
//         ...Audio.map(item => ({ type: 'Audio', data: item })),
//         ...pdf.map(item => ({ type: 'Pdf', data: item })),
//         ...WYISIWYG.map(item => ({ type: 'WYISIWYG', data: item })),
//         ...Image.map(item => ({ type: 'Image', data: item })),
//         ...Cdn.map(item => ({ type: 'Cdn', data: item })),
//     ];  

//     const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'];

//     const getFileExtension = (filePath) => {
//         const extension = filePath.split('.').pop().toLowerCase();
//         return extension;
//     };

//     const isImage = (filePath) => {
//         const extension = getFileExtension(filePath);
//         return allowedImageExtensions.includes(extension);
//     };

//     console.log("cdn" , Cdn)

//     const fileSelectHandler = (file) => {
//         onFileSelect(file);
//     };

//     const htmlFileSelectHandler = (file) => {
//         onHtmlSelect(file);
//     };

//     const showCommentHandler = (data) => { 
//         showComments(data);
//     };

//     const showNotesHandler = (data) => {
//         showNotes(data);
//     };

//     const renderGroupedContent = (type, items) => (
//         <div className='max-md:flex-row bg-white max-md:min-w-fit  w-full flex flex-col items-center gap-2 border-2 p-2'>
//             <h4 className='uppercase max-md:text-2xl  max-md:px-4'>{items.length === 1 ? type : `${type}s`}</h4>
//             {items.map((item, index) => {
//                 const thumbnailPath = item.data.globalthumbnailPath;
//                 const filePath = item.data.globalFilePath;
//                 const isValidImage = filePath && isImage(filePath);

//                 const imageSrc = thumbnailPath !== "" 
//                     ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${thumbnailPath}` 
//                     : isValidImage 
//                     ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${filePath}` 
//                     : null;

//                 return (
//                     <div key={index} className='videodivv max-md:w-80 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
//                         {imageSrc ? (
//                             <img
//                                 src={imageSrc}
//                                 className='relative h-full w-full object-cover object-center'
//                                 alt=""
//                             />
//                         ) : (
//                             null
//                         )}

//                         <div 
//                             className='absolute flex h-full items-center bg-[#00000003] justify-between w-full px-2' 
//                             style={{ backdropFilter: 'blur(5px)' }}>
                        
//                             <h5  
//                                 data-tooltip-float
//                                 data-tooltip-id='my-tooltip'
//                                 data-tooltip-content={item.data.ttsad_title}
//                                 data-tooltip-place='top'                             
//                                 className='text-black cursor-pointer w-[45%]'
//                                 style={{ textDecoration: "none", color: "black" }}
//                             >
//                                 {item.data.ttsad_title.split(' ').slice(0, 3).join(' ')}
//                                 {item.data.ttsad_title.split(' ').length > 3 ? '...' : ''}
//                             </h5>
//                             <Tooltip id='my-tooltip' />
                        
//                             <div className='flex h-full items-center w-[50%] justify-between'>
//                                 <FaPlay 
//                                     data-tooltip-id="my-tooltip"
//                                     data-tooltip-content='Play'
//                                     data-tooltip-place='top'
//                                     cursor={"pointer"} 
//                                     fontSize={26}
//                                     color='black' 
//                                     onClick={() => type === 'WYISIWYG' || type === 'Cdn' ? htmlFileSelectHandler(item.data) : fileSelectHandler(item.data)} 
//                                 />
//                                 <div className='h-full w-10 flex flex-col items-center justify-center gap-4'>
//                                     <FaRegMessage 
//                                         data-tooltip-id="my-tooltip"
//                                         data-tooltip-content='comments'
//                                         data-tooltip-place='left'
//                                         onClick={() => showCommentHandler(item.data)} 
//                                         style={{ cursor: "pointer" }} 
//                                         opacity={.7} 
//                                         fontSize={17} 
//                                         color='black' 
//                                     />
//                                     <LiaStickyNoteSolid
//                                         data-tooltip-id="my-tooltip"
//                                         data-tooltip-content='Notes'
//                                         data-tooltip-place='left'
//                                         onClick={() => showNotesHandler(item.data)} 
//                                         style={{ cursor: "pointer" }} 
//                                         opacity={.9} 
//                                         fontSize={22} 
//                                         color='black' 
//                                     />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 );
//             })}
//             {/* {items.map((item, index) => (
//                 <div key={index} className='videodivv max-md:w-80 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
//                             <img
//                                 src={
//                                     `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${item.data.globalthumbnailPath || item.data.globalFilePath }`
//                                 }
//                                 className='relative h-full w-full object-cover object-center'
//                                 alt=""
//                             />

//                         {(item.data.globalthumbnailPath === "jpg" || item.data.globalthumbnailPath === "png" || item.data.globalthumbnailPath === "Image") ?
//                             : null
//                         }
//                         <div 
//                             className='absolute flex h-full items-center bg-[#00000003] justify-between w-full px-2' 
//                             style={{ backdropFilter: 'blur(5px)' }}>                        
                        
//                         <h5  
//                             data-tooltip-float
//                             data-tooltip-id='my-tooltip'
//                             data-tooltip-content={item.data.ttsad_title}
//                             data-tooltip-place='top'                             
//                             className='text-black  cursor-pointer w-[45%]'
//                             style={{textDecoration:"none" , color:"black"}}
                            
//                         >
//                                 {item.data.ttsad_title.split(' ').slice(0, 3).join(' ')}
//                                 {item.data.ttsad_title.split(' ').length > 3 ? '...' : ''}
//                         </h5>
//                         <Tooltip id='my-tooltip'  />                               
                        
//                         <div className='flex h-full items-center w-[50%] justify-between'>
//                             <FaPlay 
//                                 data-tooltip-id="my-tooltip"
//                                 data-tooltip-content='Play'
//                                 data-tooltip-place='top'
//                                 cursor={"pointer"} 
//                                 fontSize={26}
                                 
//                                 color='black' 
//                                 onClick={() => type === 'WYISIWYG' || type === 'Cdn' ? htmlFileSelectHandler(item.data) : fileSelectHandler(item.data)} 
//                             />
//                             <div className='h-full w-10 flex flex-col items-center justify-center gap-4'>
//                                 <FaRegMessage 
//                                     data-tooltip-id="my-tooltip"
//                                     data-tooltip-content='comments'
//                                     data-tooltip-place='left'
//                                     onClick={() => showCommentHandler(item.data)} 
//                                     style={{ cursor: "pointer" }} 
//                                     opacity={.7} 
//                                     fontSize={17} 
//                                     color='black' 
//                                 />
//                                 <LiaStickyNoteSolid
//                                     data-tooltip-id="my-tooltip"
//                                     data-tooltip-content='Notes'
//                                     data-tooltip-place='left'
//                                     onClick={() => showNotesHandler(item.data)} 
//                                     style={{ cursor: "pointer" }} 
//                                     opacity={.9} 
//                                     fontSize={22} 
//                                     color='black' 
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             ))} */}
//         </div>
//     );

//     return (
//         <div className='px-2 py-2  max-md:h-fit max-md:flex-row max-md:w-full max-md:gap-2 datadiv flex flex-col items-center w-full border-b-2 gap-4 overflow-y-auto'>
//             <div className='h-full  shadow-xl max-md:border-x-2 max-md:flex-row w-full overflow-y-auto flex flex-col gap-2'>
//                 {allContent.length > 0 ? (
//                     ['Video', 'Audio', 'Pdf', 'WYISIWYG', 'Image', 'Cdn'].map(type => {
//                         const itemsOfType = allContent.filter(item => item.type === type);
//                         return itemsOfType.length > 0 ? renderGroupedContent(type, itemsOfType) : null;
//                     })
//                 ) : (
//                     <p className='w-full flex justify-center  max-md:mt-2.5'>CONTENT NOT AVAILABLE...</p>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default AllContent;



import React from 'react';
import { FaPlay, FaRegMessage } from 'react-icons/fa6';
import { LiaStickyNoteSolid } from 'react-icons/lia';
import { useFeedback } from '../../Context/FeedbackContext';
import { useConfig } from '../../ConfigContext';
import { Tooltip } from 'react-tooltip';

const AllContent = ({ onFileSelect, onHtmlSelect, showComments, showNotes }) => {
    const config = useConfig();
    const { Video, Audio, pdf, WYISIWYG, Image, Cdn , filtercontent } = useFeedback();

    const allContent = [
        ...Video.map(item => ({ type: 'Video', data: item })),
        ...Audio.map(item => ({ type: 'Audio', data: item })),
        ...pdf.map(item => ({ type: 'Pdf', data: item })),
        ...WYISIWYG.map(item => ({ type: 'WYISIWYG', data: item })),
        ...Image.map(item => ({ type: 'Image', data: item })),
        ...Cdn.map(item => ({ type: 'Cdn', data: item })),
    ];  

    const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'];

    const getFileExtension = (filePath) => {
        const extension = filePath.split('.').pop().toLowerCase();
        return extension;
    };

    const isImage = (filePath) => {
        const extension = getFileExtension(filePath);
        return allowedImageExtensions.includes(extension);
    };

    console.log("cdn" , Cdn)

    const fileSelectHandler = (file) => {
        onFileSelect(file);
    };

    const htmlFileSelectHandler = (file) => {
        onHtmlSelect(file);
    };

    const showCommentHandler = (data) => { 
        showComments(data);
    };

    const showNotesHandler = (data) => {
        showNotes(data);
    };

    const renderGroupedContent = (type, items) => (
        <div className='max-md:flex-row bg-white max-md:min-w-fit  w-full flex flex-col items-center gap-2 border-2 p-2'>
            <h4 className='uppercase max-md:text-2xl  max-md:px-4'>{items.length === 1 ? type : `${type}s`}</h4>
            {items.map((item, index) => {
                const thumbnailPath = item.data.globalthumbnailPath;
                const filePath = item.data.globalFilePath;
                const isValidImage = filePath && isImage(filePath);

                const imageSrc = thumbnailPath !== "" 
                    ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${thumbnailPath}` 
                    : isValidImage 
                    ? `${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CONTENT_PATH}${filePath}` 
                    : null;

                return (
                    <div key={index} className='videodivv max-md:w-80 relative h-44 w-full border-2 flex-shrink-0 flex items-center justify-between text-lg uppercase'>
                        {imageSrc ? (
                            <img
                                src={imageSrc}
                                className='relative h-full w-full object-cover object-center'
                                alt=""
                            />
                        ) : (
                            null
                        )}

                        <div 
                                onClick={() => type === 'WYISIWYG' || type === 'Cdn' ? htmlFileSelectHandler(item.data) : fileSelectHandler(item.data)}
                                className='absolute flex h-full items-center bg-[#00000003] justify-between w-full px-2' 
                                style={{ backdropFilter: 'blur(5px)' , zIndex:"1" }}>

                                <h5  
                                    data-tooltip-float
                                    data-tooltip-id='my-tooltip'
                                    data-tooltip-content={item.data.ttsad_title}
                                    data-tooltip-place='top'                             
                                    className='text-black cursor-pointer w-[45%]'
                                    style={{ textDecoration: "none", color: "black" }}
                                >
                                    {item.data.ttsad_title.split(' ').slice(0, 3).join(' ')}
                                    {item.data.ttsad_title.split(' ').length > 3 ? '...' : ''}
                                </h5>
                                <Tooltip id='my-tooltip' />

                                <div className='flex h-full  items-center w-[50%] justify-between'>
                                    <FaPlay 
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content='Play'
                                        data-tooltip-place='top'
                                        style={{ cursor: "pointer" , zIndex:"1" }} 
                                        fontSize={26}
                                        color='black' 
                                    />
                                    <div className='h-fit py-10 z-10 relative w-10 flex flex-col items-center justify-center gap-4'>
                                        <FaRegMessage 
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content='comments'
                                            data-tooltip-place='left'
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent click event from bubbling up
                                                showCommentHandler(item.data); 
                                            }} 
                                            style={{ cursor: "pointer" }}
                                            className='absolute top-0 ' 
                                            opacity={.7} 
                                            fontSize={17} 
                                        />
                                        <LiaStickyNoteSolid
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content='Notes'
                                            data-tooltip-place='left'
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                showNotesHandler(item.data); 
                                            }} 
                                            style={{ cursor: "pointer" }}
                                            className='absolute bottom-0 ' 
                                            opacity={.9} 
                                            fontSize={22} 
                                        />
                                    </div>
                                </div>
                            </div>


                        {/* <div 
                            onClick={() => type === 'WYISIWYG' || type === 'Cdn' ? htmlFileSelectHandler(item.data) : fileSelectHandler(item.data)}
                            className='absolute flex h-full items-center bg-[#00000003] justify-between w-full px-2' 
                            style={{ backdropFilter: 'blur(5px)' , zIndex:"1" }}>
                        
                            <h5  
                                data-tooltip-float
                                data-tooltip-id='my-tooltip'
                                data-tooltip-content={item.data.ttsad_title}
                                data-tooltip-place='top'                             
                                className='text-black cursor-pointer w-[45%]'
                                style={{ textDecoration: "none", color: "black" }}
                            >
                                {item.data.ttsad_title.split(' ').slice(0, 3).join(' ')}
                                {item.data.ttsad_title.split(' ').length > 3 ? '...' : ''}
                            </h5>
                            <Tooltip id='my-tooltip' />
                        


                            <div className='flex h-full items-center w-[50%] justify-between'>
                                <FaPlay 
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content='Play'
                                    data-tooltip-place='top'
                                    style={{ cursor: "pointer" , zIndex:"1" }} 
                                    fontSize={26}
                                    color='black' 
                                    // onClick={() => type === 'WYISIWYG' || type === 'Cdn' ? htmlFileSelectHandler(item.data) : fileSelectHandler(item.data)} 
                                />
                                <div className='h-full z-10 w-10 flex flex-col items-center justify-center gap-4'>
                                    <FaRegMessage 
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content='comments'
                                        data-tooltip-place='left'
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent click event from bubbling up
                                            showCommentHandler(item.data); 
                                        }} 
                        
                                        // onClick={() => showCommentHandler(item.data)} 
                                        style={{ cursor: "pointer" }}
                                        opacity={.7} 
                                        fontSize={17} 
                                         
                                    />
                                    <LiaStickyNoteSolid
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content='Notes'
                                        data-tooltip-place='left'
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent click event from bubbling up
                                            showNotesHandler(item.data)
                                        }} 
                        
                                        style={{ cursor: "pointer" }}
                                        opacity={.9} 
                                        fontSize={22} 
                                         
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className='px-2 py-2  max-md:h-fit max-md:flex-row max-md:w-full max-md:gap-2 datadiv flex flex-col items-center w-full border-b-2 gap-4 overflow-y-auto'>
            <div className='h-full  shadow-xl max-md:border-x-2 max-md:flex-row w-full overflow-y-auto flex flex-col gap-2'>
            {allContent.length > 0 ? (
                filtercontent === "All Content" ? (
                    // Display all types of content
                    ['Video', 'Audio', 'Pdf', 'WYISIWYG', 'Image', 'Cdn'].map(type => {
                        const itemsOfType = allContent.filter(item => item.type === type);
                        return itemsOfType.length > 0 ? renderGroupedContent(type, itemsOfType) : null;
                    })
                ) : (
                    // Display only the content of the specified type if valid
                    ['Video', 'Audio', 'Pdf', 'WYISIWYG', 'Image', 'Cdn'].includes(filtercontent) ? (
                        renderGroupedContent(filtercontent, allContent.filter(item => item.type === filtercontent))
                    ) : (
                        <p className='w-full flex justify-center max-md:mt-2.5'>CONTENT NOT AVAILABLE...</p>
                    )
                )
            ) : (
                <p className='w-full flex justify-center max-md:mt-2.5'>CONTENT NOT AVAILABLE...</p>
            )}

            </div>
        </div>
    );
};

export default AllContent;
