import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, TextField, Grid, IconButton, InputAdornment } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'; // Assuming you have toast notifications configured

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { validatePassword } from '../../componentHome/utils/Validation';
import { useConfig } from '../../ConfigContext';
import hex_md5 from "md5";

const ChangePassword = ({ passwordM, handleChangePassword }) => {
    const config = useConfig();
    const [dragging, setDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [initialOffset, setInitialOffset] = useState({ x: 0, y: 0 });
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [apiError, setApiError] = useState(null);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [passwordUpdateLoading, setPasswordUpdateLoading] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const generateSalt = (length) => {
        const characters = "QWERTYUIOPLKJHGFDSAZCXCVBNMzaqwsxcderfvtgbyhnujmikolp0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    useEffect(() => {
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;
        setPosition({ x: centerX, y: centerY });
    }, []);

    const handleMouseDown = (e) => {
        setDragging(true);
        setInitialOffset({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleMouseMove = (e) => {
        if (dragging) {
            setPosition({
                x: e.clientX - initialOffset.x,
                y: e.clientY - initialOffset.y
            });
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        if (id === "TRG_MP_txtoldpwd") {
            setOldPassword(value);
        } else if (id === "TRG_MP_txtnewpwd") {
            setPassword(value);
        } else if (id === "TRG_MP_txtconfirmnewpwd") {
            setConfirmNewPassword(value);
        }
    };

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        if (newPassword.length < 8 || newPassword !== confirmNewPassword) {
            setPasswordsMatch(false);
        } else {
            setPasswordsMatch(true);
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmNewPassword(newConfirmPassword);
        setPasswordsMatch(newConfirmPassword === password);
    };

    const toggleShowPassword = (type) => {
        if (type === 'old') {
            setShowOldPassword(!showOldPassword);
        } else if (type === 'new') {
            setShowNewPassword(!showNewPassword);
        } else if (type === 'confirm') {
            setShowConfirmNewPassword(!showConfirmNewPassword);
        }
    };

    const user = JSON.parse(localStorage.getItem('user'));
    const userid = user?.userdetails?.userid;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPasswordUpdateLoading(true);

        try {
            if (password.length < 8 || password !== confirmNewPassword) {
                throw new Error("Passwords do not match or are less than 8 characters.");
            }

            // Example validation function, replace with your own logic
            if (!validatePassword(password)) {
                throw new Error("Password requirements not met. Password must be at least 8 characters long and contain at least one special character.");
            }

            const salt = generateSalt(8);

            // Hash the password with salt
            let hashedPassword = hex_md5(password).toString();

            // Example API call using axios
            const response = await axios.post(
                `${config.REACT_APP_API_URL}/UpdatePassword`,
                {
                    userid: userid,
                    password: hashedPassword,
                    salt: salt, // Include salt in the request if necessary
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        APIKey: config.REACT_APP_API_KEY,
                    },
                }
            );

            console.log(response.data); // Log the response for verification

            toast.success("Password Change successfully!");
            handleChangePassword(); // Close modal after successful password update
            // resetForm()
        } catch (error) {
            console.error("Error Change password:", error.message);
            toast.error(error.message);
        } finally {
            setPasswordUpdateLoading(false);
        }
    };
    const resetForm = () => {
        setConfirmNewPassword()
         setPassword()
    };

    const modalStyle = {
        display: passwordM ? 'block' : 'none',
        position: 'absolute',
        top: position.y,
        left: position.x,
        transform: 'translate(-50%, -50%)',
        border: '2px solid #428bca',
        backgroundColor: '#fff',
        width: '500px',
        cursor: 'move',
        zIndex: 1000,
    };

    return (
        <>
          <ToastContainer />
        <div
            className="draggable-modal"
            style={modalStyle}

        >
            
            <Box sx={{ p: 2 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item style={{ marginLeft: "10px", width: '98%' }} onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontSize: "15px" }}>
                                Change password
                            </Typography>
                            <Button onClick={handleChangePassword} color="inherit" style={{ fontSize: "20px" }}>
                                ×
                            </Button>
                        </Box>
                    </Grid>

                    <TextField
                        fullWidth
                        label="Old password *"
                        type={showOldPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        variant="outlined"
                        id="TRG_MP_txtoldpwd"
                        onChange={handleInputChange}
                        value={oldPassword}
                        style={{ margin: '10px', width: '97%', display: "none" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => toggleShowPassword('old')} edge="end">
                                        {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: { fontSize: "13px" } // Adjust the label font size here
                        }}
                        title="Please enter your old password"
                    />

                    <TextField
                        fullWidth
                        label="New password *"
                        type={showNewPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        variant="outlined"
                        id="TRG_MP_txtnewpwd"
                        onChange={handlePasswordChange}
                        value={password}
                        error={password.length > 0 && !validatePassword(password)}
                        helperText={
                            password.length > 0 && !validatePassword(password)
                                ? "Password must be at least 8 characters long and contain at least one special character"
                                : ""
                        }
                        style={{ margin: '10px', width: '97%' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => toggleShowPassword('new')} edge="end">
                                        {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: { fontSize: "13px" } // Adjust the label font size here
                        }}
                        title="Please enter your new password"
                    />

                    <TextField
                        fullWidth
                        label="Confirm New password *"
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        variant="outlined"
                        id="TRG_MP_txtconfirmnewpwd"
                        onChange={handleConfirmPasswordChange}
                        value={confirmNewPassword}
                        error={!passwordsMatch}
                        helperText={!passwordsMatch ? "Passwords do not match" : ""}
                        style={{ margin: '10px', width: '97%' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => toggleShowPassword('confirm')} edge="end">
                                        {showConfirmNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: { fontSize: "13px" } // Adjust the label font size here
                        }}
                        title="Please confirm your new password"
                    />

                    {apiError && (
                        <Grid item>
                            <Typography variant="body2" color="error">
                                {apiError}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                color="primary"
                                disabled={!passwordsMatch || passwordUpdateLoading}
                            >
                                Change
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={handleChangePassword}
                                style={{ marginLeft: '10px' }}
                                disabled={passwordUpdateLoading}
                            >
                                Close
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
        </>
    );
};

export default ChangePassword;
