import React, { useEffect, useState, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { GoClock } from 'react-icons/go';
import { IoPrintOutline } from 'react-icons/io5';
import { CiSearch } from 'react-icons/ci';
import { GiSpeaker } from 'react-icons/gi';
import Breadcrumb from '../../../componentHome/commonComponent/Breadcrumbs';
import { useFeedback } from '../../../Context/FeedbackContext';
import { useConfig } from '../../../ConfigContext';
import { useUser } from '../../../UserContext';
import axios from 'axios';
import { Tooltip } from 'react-tooltip';
import Loader from '../../../pages/Loader';
import { IoClose } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Modal from 'react-modal';
import Draggable from 'react-draggable';
import CustomDayCell from './CustomDayCell.jsx';
import CreateSessionFromCalendar from './CreateSessionFromCalendar.jsx';

Modal.setAppElement('#root');

const parseHTML = (htmlString) => {
  return (
    <span dangerouslySetInnerHTML={{ __html: htmlString }} />
  );
};

const CustomEvent = ({ event }) => {
  const backgroundColor = event.facultyname ? '#008000' : '#A52A2A';

  return (
    <div
      style={{ backgroundColor, color: 'white', padding: '2px 4px', fontSize: "10px", borderRadius: '4px' }}>
      {parseHTML(event.title)}
    </div>
  );
};

const localizer = momentLocalizer(moment);

const SessionCalendar = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [searchTrainingCode, setSearchTrainingCode] = useState("");
  const [filter, setFilter] = useState('all');
  const { ContentType, selectedDate, setSelectedDate,showModal, setShowModal , setallfaculty } = useFeedback()

  const [eventTitle, setEventTitle] = useState('');
  const [selectedTraining, setSelectedTraining] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [day, setDay] = useState('');
  const [week, setWeek] = useState('');
  const [durationTime, setDurationTime] = useState('');
  const [durationUnit, setDurationUnit] = useState('min');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [module, setModule] = useState('');
  const [faculty, setFaculty] = useState('');

  const [date, setDate] = useState(() => {
    const today = new Date();
    // Format the date as 'YYYY-MM-DD'
    return today.toISOString().split('T')[0];
  });

  const datefunction = () => {
    const today = new Date();
    setDate(today.toISOString().split('T')[0]);

  }

  const timefunction = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    setTime(`${hours}:${minutes}`);
  }

  const [time, setTime] = useState(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  });

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*\.?\d*$/.test(value)) {
      setDurationTime(value);
    }
  };

  useEffect(() => {
    datefunction()
    timefunction()
  }, []);


  const getFaculty = async () => {
    setLoading(true);
    const headers = {
      Accept: 'application/json, text/plain',
      APIKey: config?.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    };

    try {
      const { data } = await axios.get(`${config?.REACT_APP_API_URL}/Agency`, {
        headers: headers,
        params: {
          agencytype: "00054",
        }
      }
      );
      setallfaculty(data);
      console.log("faculties",data)
      setLoading(false);
    } catch (error) {
      console.log('Error session type', error);
      setLoading(false);
    }
  };



  const config = useConfig();
  const { loading, setLoading, SessionCalendar, setSessionCalendar , sessionType, setsessionType } = useFeedback();
  const { selectedYear, selectedUserType } = useUser();
  const user = JSON.parse(localStorage.getItem('user'));
  // const [sessionType, setsessionType] = useState(null)
  // const [selectedSessionTypeId, setSelectedSessionTypeId] = useState(null);  
  // const [allChecked, setAllChecked] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filtercontent, setfiltercontent] = useState(false);


  const handleFilter = (filterdata) => {
    setfiltercontent(filterdata.value)
  }

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };

  const handleSelectSlot = (slotInfo) => {
    setShowModal(true);
    setRightClickPosition({ x: 0, y: 0 })
    console.log("slotInfo", slotInfo);
    
  };

  const handleSelectEvent = (event) => {
    console.log("eventDetails", event)
    setSelectedEvent(event);
    setShowEventDetailsModal(true);
  };

  const saveEvent = () => {
    if (eventTitle && selectedDate) {
      const newEvent = {
        title: eventTitle,
        start: selectedDate,
        end: moment(selectedDate).add(1, 'hours').toDate()
      };
      setEvents([...events, newEvent]);
      setFilteredEvents([...events, newEvent]); // Ensure filteredEvents is also updated
      setShowModal(false);
      setEventTitle("");
    }
  };

  const handleSearch = () => {
    let matchedEvents = events;

    if (searchTrainingCode) {
      matchedEvents = matchedEvents.filter(event => event.title.includes(searchTrainingCode));
    }

    if (filter === 'withSpeaker') {
      matchedEvents = matchedEvents.filter(event => event.facultyname);
    } else if (filter === 'withoutSpeaker') {
      matchedEvents = matchedEvents.filter(event => !event.facultyname);
    }

    setFilteredEvents(matchedEvents);
  };

  useEffect(() => {
    handleSearch(); // Call handleSearch whenever searchTrainingCode or filter changes
  }, [searchTrainingCode, filter]);

  const paths = [
    { title: 'Home', url: '/' },
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'Session Calendar', url: '/Session_Calendar' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (selectedYear) {
        await getSessionCalendarData();
      }
    };

    fetchData();
  }, [selectedUserType, selectedYear]);

  useEffect(() => {
    const fetchData = async () => {
      await getSessionType()
      await getFaculty()
    };
    fetchData();
  }, []);

  useEffect(() => {
    handleSearch(); // Call handleSearch whenever events change
  }, [events]);

  const formatSessionData = async (data) => {
    setLoading(true);

    return data.items.map(item => {
      // Parse the start date and time
      const start = moment(`${item.ttttt_session_dt} ${item.ttttt_session_time}`, 'YYYY-MM-DD HH:mm').toDate();

      // For the end date, just use the start date
      const end = new Date(start); // end is the same as start

      // Construct the event title
      const title = `${item.session_type_icon} ${item.trainingcode} ${item.ttttt_content_desc} ${item.ttttt_session_time}`;

      return {
        eventDetails: item,
        title: title,
        start: start,
        end: end,
        sessionid: item.ttttt_session_id,
        facultyname: item.facultyname
      };
    });
  };

  const getSessionCalendarData = async () => {
    if (user) {
      setLoading(true);
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };

      try {
        const startDate = `${selectedYear.split('-')[0]}-04-01`;
        const endDate = `${selectedYear.split('-')[1]}-03-31`;

        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/UserSessions`, {
          headers: headers,
          params: {
            usertype: selectedUserType,
            userid: user?.userdetails?.agencyid,
            trg_startdate: startDate,
            trg_enddate: endDate,
          }
        });
        setSessionCalendar(data);

        const formattedEvents = await formatSessionData(data);
        setEvents(formattedEvents);
        setFilteredEvents(formattedEvents); // Initialize filteredEvents with all events
        setLoading(false);
      } catch (error) {
        console.log('Error Calendar data', error);
      }
    } else {
      console.log('No user provided');
      setLoading(false);
    }
  };

  const getSessionType = async () => {
    if (user) {
      setLoading(true);
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };

      try {
        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/SessionType`, {
          headers: headers,
        }
        );
        setsessionType(data);
        console.log("sessionType", sessionType)
      } catch (error) {
        console.log('Error session type', error);
      }
    } else {
      setLoading(false);
    }
  };

  const [selectedSessionTypeIds, setSelectedSessionTypeIds] = useState([]);
  const [allChecked, setAllChecked] = useState(true);

  const handleFilterSelect = (id) => {
    if (selectedSessionTypeIds.includes(id)) {
      setSelectedSessionTypeIds(selectedSessionTypeIds.filter(selectedId => selectedId !== id));
    } else {
      setSelectedSessionTypeIds([...selectedSessionTypeIds, id]);
    }
    setAllChecked(false); // Uncheck "All" if any individual item is selected or deselected
  };

  const handleAllToggle = () => {
    if (allChecked) {
      setAllChecked(false);
      setSelectedSessionTypeIds([]);
    } else {
      setAllChecked(true);
      setSelectedSessionTypeIds(sessionType.map(session => session.id));
    }
  };


  const menuRef = useRef(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const monthViewRef = useRef(null);
  const [rightClickPosition, setRightClickPosition] = useState({ x: 0, y: 0 });
  const [newEventTitle, setNewEventTitle] = useState('');
  const [overlappingEvent, setOverlappingEvent] = useState(null);
  const calendarRef = useRef(null);
  const buttonRef = useRef(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (calendarRef.current) {
  //       const calendarRect = calendarRef.current.getBoundingClientRect();
  //       setRightClickPosition(prevPosition => {
  //         const newCalendarRect = calendarRef.current.getBoundingClientRect();
  //         return {
  //           x: prevPosition.x + (newCalendarRect.left - calendarRect.left),
  //           y: prevPosition.y + (newCalendarRect.top - calendarRect.top),
  //         };
  //       });
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [rightClickPosition]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     // If there's a right-click menu open, recalculate its position
  //     if (calendarRef.current && (rightClickPosition.x !== 0 || rightClickPosition.y !== 0)) {
  //       const calendarRect = calendarRef.current.getBoundingClientRect();
  //       setRightClickPosition(prevPosition => ({
  //         x: prevPosition.x + (calendarRect.left - calendarRect.left),
  //         y: prevPosition.y + (calendarRect.top - calendarRect.top),
  //       }));
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [rightClickPosition]);

  const handleContextMenu = (e) => {
    e.preventDefault();

    if (e.target.classList.contains('rbc-day-bg') || e.target.classList.contains('rbc-row') || e.target.classList.contains('rbc-date-cell')) {
      const calendarRect = calendarRef.current.getBoundingClientRect();

      setRightClickPosition({
        x: e.clientX - calendarRect.left,
        y: e.clientY - calendarRect.top,
      });


      let selectedDate;
      if (e.target.classList.contains('rbc-day-bg')) {
        selectedDate = new Date(e.target.getAttribute('date-date'));
        console.log("hh", selectedDate)
      } else if (e.target.classList.contains('rbc-date-cell')) {
        const dateText = e.target.innerText.trim();
        selectedDate = moment(dateText, 'MMM D').toDate();
        console.log("top", selectedDate)
      }

      if (isNaN(selectedDate?.getTime())) {
        console.error('Invalid date format:', selectedDate);
      } else {
        setSelectedDate(selectedDate);
        console.log('Selected Date:', selectedDate);
      }

      setOverlappingEvent(null);
    } else {
      setRightClickPosition({ x: 0, y: 0 });
      setSelectedDate(null);
      setOverlappingEvent(null);
    }
  };

  const handleClickOutside = (e) => {
    if (buttonRef.current && !buttonRef.current.contains(e.target) && !calendarRef.current.contains(e.target)) {
      setRightClickPosition({ x: 0, y: 0 });
    }
  };

  const checkOverlap = (date) => {
    return events.find(
      (event) =>
        moment(date).isBetween(event.start, event.end, undefined, '[)') ||
        moment(date).isSame(event.start) ||
        moment(date).isSame(event.end)
    );
  };

  const handleCreateSessionClick = () => {
    const overlapping = checkOverlap(selectedDate);

    if (overlapping) {
      setOverlappingEvent(overlapping);
      alert(`Cannot create session. Overlaps with: ${overlapping.title}`);
    } else {
      setModalIsOpen(true);
      setRightClickPosition({ x: 0, y: 0 });
    }
  };

  useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleCreateSession = () => {
    // Your logic to create a session
    console.log('Creating session...');
    handleCloseContextMenu(); // Close menu after action
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };


  if (loading) {
    <Loader />
  }

  return (
    <>
      <Tooltip id='mytool' />
      <Breadcrumb paths={paths} />
      <div className='h-full w-full p-4 max-phone:p-2'>
        <div
          style={{ border: ".5px solid rgba(128, 128, 128, 0.308)", borderRadius: "5px" }}
          className='w-full max-xl:items-start bg-white p-4 mb-4 flex flex-col gap-4'
        >
          <div className='flex max-xl:w-fit justify-between max-xl:block'>
            <div className='h-full w-fit flex flex-col justify-between gap-6'>

              <div className='flex max-mid:block items-center'>
                <div className='flex gap-2 max-phone:block items-center max-mid:mb-4'>
                  <span>Training Code</span>
                  <div className='flex max-phone:mx-0 max-phone:mt-4 mx-4'
                    style={{ border: ".5px solid rgba(128, 128, 128, 0.308)", borderRadius: "5px" }}
                  >
                    <input
                      style={{ borderRight: ".5px solid rgba(128, 128, 128, 0.308)" }}
                      className='px-3 py-2 rounded-md' type="text"
                      value={searchTrainingCode}
                      onChange={(e) => setSearchTrainingCode(e.target.value)}
                      placeholder='Enter Training Code'
                    />
                    <div className='flex items-center justify-center bg-[#f5f5dc69] h-[31px] w-[30px] cursor-pointer' onClick={handleSearch}>
                      <CiSearch />
                    </div>
                  </div>
                </div>
                <div className='flex gap-2'>
                  <button
                    className='py-2 px-4 h-full gap-2 rounded-md flex items-center text-white bg-[#428BCA]'
                    onClick={() => { setFilter('all'); handleSearch(); }}
                  >
                    <GoClock /> Recurring Session
                  </button>
                  <div className='h-[30px] flex justify-center items-center w-[30px] bg-[#428BCA] rounded-md'>
                    <IoPrintOutline color='white' fontSize={16} />
                  </div>
                </div>
              </div>

            </div>
            <div className='h-full max-phone:block max-xl:justify-between flex max-xl:mt-4 gap-4'>

              <Menu as="div" className="relative inline-block text-left">
                <div className="">
                  <MenuButton
                    className="inline-flex w-full max-md:max-w-72 max-mid:max-w-96 items-center relative gap-x-2 overflow-hidden rounded-md bg-white px-2.5 py-3 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
                  >
                    {(() => {
                      if (allChecked) {
                        return (
                          <>
                            <i className="default-icon-class"></i>
                            All
                          </>
                        );
                      }
                      const selectedSession = sessionType.find(session => selectedSessionTypeIds.includes(session.id));
                      return (
                        <>
                          {selectedSession && <i className={selectedSession.displayClass}></i>}
                          {selectedSession ? selectedSession.name : 'None Selected'}
                        </>
                      );
                    })()}
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  className="absolute bg-white max-phone:w-[90vw] min-h-fit max-h-[50vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                >
                  <div className="py-1 w-full">
                    <MenuItem>
                      <button
                        onClick={handleAllToggle}
                        className={`block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full ${allChecked ? 'bg-[#1976d2] text-white' : ''
                          }`}
                      >
                        <span className="flex py-2 items-center gap-2">
                          <input
                            type="checkbox"
                            checked={allChecked}
                            readOnly
                            className="mr-2"
                          />
                          <span>All</span>
                        </span>
                      </button>
                    </MenuItem>

                    {sessionType?.map(session => (
                      <MenuItem key={session.id}>
                        <button
                          onClick={() => handleFilter(session.id)}
                          className={`block px-4 flex-shrink-0 whitespace-nowrap text-sm text-gray-700 w-full ${selectedSessionTypeIds.includes(session.id) ? 'text-[#1976d2] ' : ''
                            }`}
                        >
                          <span className="flex py-2 items-center gap-2">
                            <input
                              type="checkbox"
                              checked={selectedSessionTypeIds.includes(session.id)}
                              onChange={() => handleFilterSelect(session.id)}
                              className="mr-2"
                              disabled={allChecked}
                            />
                            <i className={session.displayClass}></i>
                            <span>{session.name}</span>
                          </span>
                        </button>
                      </MenuItem>
                    ))}
                  </div>
                </MenuItems>
              </Menu>

              <div
                style={{ border: ".5px solid rgba(128, 128, 128, 0.308)", borderRadius: "3px" }}
                className='flex h-[35px] w-fit bg-[#D2D5D8] max-phone:mt-4'
              >
                <button
                  className={`px-4 text-[12px] ${filter === 'all' ? 'text-[#198A19]' : 'text-gray-600'}`}
                  onClick={() => { setFilter('all'); handleSearch(); }}
                >
                  All
                </button>
                <button
                  style={{ borderRight: ".5px solid rgba(128, 128, 128, 0.308)", borderLeft: ".5px solid rgba(128, 128, 128, 0.308)" }}
                  className={`px-4 text-[12px] ${filter === 'withSpeaker' ? 'text-[#198A19]' : 'text-gray-600'}`}
                  onClick={() => { setFilter('withSpeaker'); handleSearch(); }}
                >
                  With Speaker
                </button>
                <button
                  className={`px-4 text-[12px] flex items-center ${filter === 'withoutSpeaker' ? 'text-[#AC4445]' : 'text-gray-600'}`}
                  onClick={() => { setFilter('withoutSpeaker'); handleSearch(); }}
                >
                  <div className='flex items-center gap-1'>
                    <GiSpeaker />
                    Without Speaker
                  </div>
                </button>
              </div>

            </div>
          </div>
          <span className='capitalize text-[#A52A2A] text-[12px]'>
            note: self paced not shown in calendar to see all type Session go to session list.
          </span>
        </div>

        <div ref={calendarRef} className='relative h-full w-full' >

          <Calendar
            events={filteredEvents}
            views={['month', 'day', 'week']}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "90vh" }}
            selectable={true}
            onSelectEvent={handleSelectEvent}
            components={{
              event: CustomEvent,
              dateCellWrapper: CustomDayCell,
            }}

          />


          {rightClickPosition.x !== 0 && rightClickPosition.y !== 0 && (
            <div
              ref={buttonRef}
              style={{
                position: 'absolute',
                top: rightClickPosition.y,
                left: rightClickPosition.x,
                zIndex: 1000,
              }}
            >
              <button
                onClick={handleSelectSlot}
                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
              >
                Create Session
              </button>
            </div>
          )}


          {showModal && (
            // <div className='absolute z-[99]'>
            <div className='absolute z-[99]'>
              <CreateSessionFromCalendar />
            </div>
            // <div className='fixed z-10 top-0 left-0 right-0 bottom-0  bg-gray-700 bg-opacity-50 flex justify-center items-center'>
            //   <Draggable handle=".modal-header">
            //     <div className='bg-white py-6 border-[#428BCA] rounded shadow-lg'>
            //       <div className="modal-header px-6  cursor-move pb-4 border-b-2 flex items-center justify-between ">
            //         <span className='text-3xl'>Create Session</span>
            //         <IoClose
            //           cursor={"pointer"}
            //           onClick={() => setShowModal(false)}
            //         />
            //       </div>

            //       <div className='flex  flex-col max-h-[55vh] overflow-x-hidden overflow-y-auto  px-6 py-4 gap-4'>
            //         <div className='flex w-[45vw] max-mid:w-[65vw] max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center'>
            //           <span className='w-40 '>Training <span className='text-red-600'>*</span></span>
            //           <select
            //             className='w-full rounded-md px-1 py-2 border-2'
            //             value={selectedTraining}
            //             onChange={(e) => setSelectedTraining(e.target.value)}
            //           >
            //             <option value="">Select Training</option>
            //           </select>
            //         </div>

            //         <div className='flex w-[45vw] max-mid:w-[65vw] max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center'>
            //           <span className='w-40 '>Type <span className='text-red-600'>*</span></span>
            //           <Menu as="div" className="relative w-full inline-block text-left">
            //             <div className="w-full ">
            //               <MenuButton
            //                 className="inline-flex h-11 w-full items-center justify-between px-6 relative gap-x-1.5 overflow-hidden rounded-md bg-white py-2 text-sm font-medium capitalize text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
            //               >
            //                 {(() => {
            //                   const session = sessionType?.find(item => item.value === filtercontent);

            //                   if (session) {
            //                     return (
            //                       <>
            //                         <span className="flex items-center  justify-start gap-4">
            //                           {session.Logo}
            //                           <span>{session.title}</span>
            //                         </span>
            //                       </>
            //                     );
            //                   } else {
            //                     return 'Select Content';
            //                   }
            //                 })()}
            //                 <ChevronDownIcon
            //                   aria-hidden="true"
            //                   className="max-mid:absolute top-1.5 right-[.6px] max-mid:h-[70%] bg-white flex items-center w-7 text-black"
            //                 />
            //               </MenuButton>
            //             </div>

            //             <MenuItems
            //               //  className="absolute bg-white w-full max-phone:w-[90vw] min-h-fit max-h-[50vh] overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
            //               className="absolute bg-white w-full  overflow-y-auto left-0 z-10 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
            //             >
            //               <div className="py-1 w-full flex flex-col min-h[5vh] overflow-y-auto bg-green-300">
            //                 {sessionType?.map(session => (
            //                   <MenuItem key={session.id}>
            //                     <button
            //                       value={session.value}
            //                     // onClick={() => handleFilterSelect(session)}
            //                     // className={`block px-6 flex-shrink-0  whitespace-nowrap text-sm text-gray-700 w-full ${
            //                     //     filtercontent === session.value ? 'bg-[#1976d2] text-white' : ''
            //                     // }`}
            //                     >
            //                       <span className="flex py-2  items-center justify-start gap-4">
            //                         <i className={session.displayClass}></i>
            //                         <span>{session.name}</span>
            //                       </span>
            //                     </button>
            //                   </MenuItem>
            //                 ))}
            //               </div>
            //             </MenuItems>
            //           </Menu>
            //         </div>

            //         <div className='flex max-mid:items-start max-mid:gap-4 max-mid:py-2 max-mid:flex-col items-center '>
            //           <span className='w-40 '>Type <span className='text-red-600'>*</span></span>
            //           <select
            //             className='w-full rounded-md px-1 py-2 border-2'
            //             value={selectedType}
            //             onChange={(e) => setSelectedType(e.target.value)}
            //           >
            //             <option value="">Select Type</option>
            //           </select>
            //         </div>

            //         <div className='flex flex-col gap-4 max-mid:justify-between max-mid:flex-row'>

            //           <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>
            //             <div className='flex items-center max-mid:items-start max-mid:flex-col max-mid:gap-2'>
            //               <span className='w-32 '>Date</span>
            //               <input
            //                 type='text'
            //                 value={moment(selectedDate).format('DD-MM-YYYY')}
            //                 readOnly
            //                 className='rounded-md  max-mid:ml-0 ml-2 px-1 border-2'
            //               />
            //             </div>

            //             <div className='flex items-center max-mid:items-start max-mid:flex-col max-mid:gap-2'>
            //               <span className='ml-4 max-mid:ml-0 mr-8 '>Time</span>
            //               <input
            //                 type='time'
            //                 value={time}
            //                 onChange={(e) => setTime(e.target.value)}
            //                 className='rounded-md px-1  border-2'
            //               />
            //             </div>

            //           </div>

            //           <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>
            //             <span className='w-28   mr-[5px]'>Day</span>
            //             <select
            //               className='w-20 rounded-md px-1 py-2 border-2'
            //               value={day}
            //               onChange={(e) => setDay(e.target.value)}
            //             >
            //               <option value="">1</option>
            //             </select>

            //             <span className='ml-6 max-mid:mx-0 mr-8'>Week</span>
            //             <select
            //               className='w-20 rounded-md px-1 py-2 border-2'
            //               value={week}
            //               onChange={(e) => setWeek(e.target.value)}
            //             >
            //               <option value="">1</option>
            //             </select>
            //           </div>

            //           <div className='flex max-mid:items-start max-mid:flex-col items-center gap-4'>
            //             <span className='w-24  mr-[15px]'>Duration<span className='text-red-600'>*</span></span>
            //             <input
            //               type='number'
            //               value={durationTime}
            //               onChange={handleChange}
            //               className='min-w-24 rounded-md px-1 py-2 border-2'
            //               placeholder='Time'
            //               min='0'
            //               step='1'
            //             />
            //             <select
            //               className=' rounded-md px-1 py-2 border-2'
            //               value={durationUnit}
            //               onChange={(e) => setDurationUnit(e.target.value)}
            //             >
            //               <option value='min'>Min</option>
            //               <option value='hr'>Hr</option>
            //             </select>
            //           </div>

            //         </div>


            //         <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
            //           <span className='w-36  mr-[9px]'>Subject<span className='text-red-600'>*</span></span>
            //           <input
            //             type='text'
            //             value={subject}
            //             onChange={(e) => setSubject(e.target.value)}

            //             className='w-full rounded-md px-1 py-2 border-2'
            //           />
            //         </div>

            //         <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
            //           <span className='w-40 '>Description<span className='text-red-600'>*</span></span>
            //           <input
            //             type='text'
            //             value={description}
            //             onChange={(e) => setDescription(e.target.value)}
            //             className='w-full rounded-md px-1 py-2 border-2'
            //           />
            //         </div>

            //         <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
            //           <span className='w-36  mr-[9px]'>Faculty</span>
            //           <select
            //             className='w-full rounded-md px-1 py-2 border-2'
            //             value={faculty}
            //             onChange={(e) => setFaculty(e.target.value)}
            //           >
            //             <option value="">Select Faculty</option>
            //           </select>
            //         </div>

            //         <div className='flex max-mid:items-start max-mid:gap-4  max-mid:flex-col items-center '>
            //           <span className='w-36  mr-[9px]'>Module<span className='text-red-600'>*</span></span>
            //           <select
            //             className='w-full rounded-md px-1  py-2 border-2'
            //             value={module}
            //             onChange={(e) => setModule(e.target.value)}
            //           >
            //             <option value="">Select Module</option>
            //           </select>
            //         </div>

            //       </div>

            //       {/* <input
            //       type='text'
            //       value={eventTitle}
            //       onChange={(e) => setEventTitle(e.target.value)}
            //       placeholder='Enter event title'
            //       className='border p-2 rounded w-full mt-2'
            //       /> */}
            //       <div className='w-full  flex items-center px-6 justify-between mt-4 border-t-2 pt-6'>

            //         <button
            //           // onClick={() => setShowModal(false)}
            //           className='bg-[#CCCCCC] text-black px-4 py-2 rounded'
            //           onClick={() => setShowModal(false)}

            //         >
            //           Close
            //         </button>

            //         <button
            //           // onClick={saveEvent}
            //           className='bg-[#428BCA] text-white px-4 py-2 rounded '
            //         >
            //           Create Session
            //         </button>
            //       </div>
            //     </div>
            //   </Draggable>
            // </div>
          )}

          {showEventDetailsModal && selectedEvent && (
            <div className='fixed z-10 max-sm:px-20 px-6 top-0 left-0 right-0 bottom-0 bg-gray-700 bg-opacity-50 flex justify-center items-center'>
              <div className='bg-white p-6 mx-4 rounded shadow-lg'>

                <div className='pb-4 border-b-2 flex items-center justify-between w-full'>
                  <span className='text-3xl'>Session Details</span>
                  <IoClose cursor={"pointer"} onClick={() => setShowEventDetailsModal(false)} />
                </div>

                <div className='flex py-6 gap-10'>
                  <div className='flex flex-col gap-8 px-4'>
                    <span className='' >Training Code</span>
                    <span className='' >Training Title</span>
                    <span className='' >Subject</span>
                    <span className='' >Description</span>
                    <span className='' >Date & Time (Duration)</span>
                    {
                      selectedEvent?.eventDetails?.facultyname !== "" ?
                        <span className='' >Faculty</span>
                        :
                        null
                    }
                  </div>

                  <div className='flex flex-col gap-8 px-4 w-96'>

                    <NavLink style={{ textDecoration: "none" }}>
                      <span className='' >{selectedEvent?.eventDetails?.trainingcode}</span>
                    </NavLink>

                    <span className='' >{selectedEvent?.eventDetails?.training_title}</span>
                    <span className='' >{selectedEvent?.eventDetails?.ttttt_content_desc}</span>
                    <span className='' >{parseHTML(selectedEvent?.eventDetails?.session_type_icon)} {selectedEvent?.eventDetails?.ttttt_subject}</span>
                    <span className='' >{selectedEvent?.eventDetails?.ttttt_session_dt} {selectedEvent?.eventDetails?.ttttt_session_time} ({selectedEvent?.eventDetails?.ttttt_session_duration} {selectedEvent?.eventDetails?.ttttt_session_duration_type_name})</span>
                    {
                      selectedEvent?.eventDetails?.facultyname !== "" ?
                        <span className='' >{selectedEvent?.eventDetails?.facultyname}</span>
                        :
                        null
                    }
                  </div>

                </div>

                <div className='mt-4 flex justify-between items-center border-t-2 pt-6'>

                  <button
                    onClick={() => setShowEventDetailsModal(false)}
                    className='bg-gray-500 text-white px-4 py-2 rounded'
                  >
                    Close
                  </button>

                  <div className='flex gap-4  '>

                    <button
                      className='bg-[#428BCA] text-white px-4 py-2 rounded'
                    >
                      Update
                    </button>

                    <button
                      className='bg-[#d9534f] text-white px-4 py-2 rounded'
                    >
                      Delete
                    </button>

                  </div>

                </div>

              </div>
            </div>
          )}

        </div>

      </div>
    </>
  );
};

export default SessionCalendar;


