const loadConfig = async () => {
  try {
    const response = await fetch(`/content/GlobalSetting/config.json`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const config = await response.json();
    return config;
  } catch (error) {
    console.error("Error fetching config: ", error);
    throw error;
  }
};
export default loadConfig;
