import React from 'react'
import TestNavbar from '../components/teastpage/TestNavbar'
import SessionCalender from '../components/Content/Calender/SessionCalender'

const TestSessionCalendar = () => {
  return (
    <TestNavbar>
        <SessionCalender/>
    </TestNavbar>
  )
}

export default TestSessionCalendar