import React, { useContext } from "react";
import Select from "react-select";
import { TranslationContext } from "../../TranslationContext";
import icon1 from "../../assest/flag-in.png";
import icon2 from "../../assest/flag-tm.png";
import icon3 from "../../assest/flag-us.png";

// Define options data including name and icon
const options = [
  { value: "en", label: "En", image: icon3 },
  { value: "hi", label: "Hi", image: icon2 },
  { value: "ta", label: "Ta", image: icon1 },
];

const CustomOption = ({ innerRef, innerProps, data }) => (
  <div
    ref={innerRef}
    {...innerProps}
    style={{ display: "flex", alignItems: "center", border: "none", height: "20px",color:"#000" }}
  >
    <img
      src={data.image}
      alt={data.label}
      style={{ width: 20, height: 20, marginRight: 10 }}
    />
    {data.label}
  </div>
);

const CustomSelect = () => {
  const { language, setLanguage } = useContext(TranslationContext);

  const handleChange = (selectedOption) => {
    setLanguage(selectedOption.value);
  };

  // Custom styles for the select input
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#1677ff4a",
      color: "white",

      width: "100px", // Set width
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0, // Remove padding
    }),
  };

  return (
    <Select
      value={options.find((option) => option.value === language)}
      onChange={handleChange}
      options={options}
      formatOptionLabel={(data) => <CustomOption data={data} />}
      styles={customStyles} // Apply custom styles
    />
  );
};

export default CustomSelect;
