import React from "react";
import TestNavbar from "../components/teastpage/TestNavbar";
import AddPositionSidebar from "../componentHome/fromscomponent/AddPositionSidebar";

const AddPositionSideberPage = () => {
  return (
    <>
      <TestNavbar>
        <AddPositionSidebar></AddPositionSidebar>
      </TestNavbar>
    </>
  );
};

export default AddPositionSideberPage;
