import React, { useEffect, useState } from "react";
import { Form, Select, Button, Tag, Typography, Space, message, Modal } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import 'antd/dist/reset.css'; // Import Ant Design CSS
import Breadcrumb from "../commonComponent/Breadcrumbs";
import { useConfig } from "../../ConfigContext";
import { useUser } from "../../UserContext";

const { Option } = Select;

const TestDescription = () => {
  const navigate = useNavigate()
  const { setTestDataOther, setTestTime } = useUser();
  const config = useConfig();
  const [categories, setCategories] = useState([]);
  const [skillTags, setSkillTags] = useState([]);
  const [difficultyLevels, setDifficultyLevels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSkillTags, setSelectedSkillTags] = useState([]);
  const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState("");
  const [testData, setTestData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false); // Manage modal visibility
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [selfTest, categoriesRes, difficultyLevelsRes] = await Promise.all([
          axios.get(`${config.REACT_APP_API_URL}/Get_Self_Test_Configuration`, {
            headers: { APIKey: config?.REACT_APP_API_KEY }
          }),
          axios.get(`${config.REACT_APP_API_URL}/Categories`, {
            headers: { APIKey: config?.REACT_APP_API_KEY }
          }),
          axios.get(`${config.REACT_APP_EVAL_API_PATH}/QuestionDifficulties`, {
            headers: {
              "Content-Type": "application/json",
              "API-Key": config?.REACT_APP_API_KEY
            }
          })
        ]);
        setTestData(selfTest.data);
        setTestTime(selfTest.data);
        setCategories(categoriesRes.data);
        setDifficultyLevels(difficultyLevelsRes.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [config]);

  useEffect(() => {
    if (selectedCategory) {
      const fetchSkillTags = async () => {
        try {
          const skillTagsRes = await axios.get(`${config.REACT_APP_EVAL_API_PATH}/Tests/Category_Question_Tags?trainingcategoryid=${selectedCategory}`, {
            headers: { "API-Key": config?.REACT_APP_API_KEY }
          });
          setSkillTags(skillTagsRes.data);
        } catch (error) {
          console.error('Error fetching skill tags:', error);

        }
      };
      fetchSkillTags();
    } else {
      setSkillTags([]);
    }
  }, [selectedCategory, config]);

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleSkillTagsChange = (value) => {
    setSelectedSkillTags(value);
  };

  const handleDifficultyLevelChange = (value) => {
    setSelectedDifficultyLevel(value);
  };

  const handleSubmit = async () => {
    if (!selectedCategory || !selectedSkillTags.length || !selectedDifficultyLevel) {
      message.error('Please fill all the required fields.');
      return;
    }

    const skillSet = selectedSkillTags.join(",");
    const payload = {
      skillSet,
      questionCount: testData.no_of_question,
      questionDifficutyID: selectedDifficultyLevel,
      trainingCategory: selectedCategory
    };

    try {
      const response = await axios.post(`${config.REACT_APP_EVAL_API_PATH}/TestQuestions/Questions`, payload, {
        headers: { "API-Key": config?.REACT_APP_API_KEY }
      });

      // Extracting response data
      const responseData = response.data;
      setTestDataOther(responseData)
      if (responseData.questions.length < testData.no_of_question) {
        setModalContent('No of Question in selected tags and level are less than Question required to conduct test. Please Contact to admin.');
        setIsModalVisible(true);
      } else {

        navigate('/start-test')
        console.log('Response data:', responseData.questions);

      }

    } catch (error) {
      console.error('Error submitting data:', error);


    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const paths = [
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'Self Test', url: '' }
  ];

  return (
    <>
      <Breadcrumb paths={paths} />
      <div
        style={{
          padding: '24px',
          backgroundColor: '#f9f9f9',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          marginLeft: "1%",
          margin: 'auto',
          marginTop: '10px',
          width: "98%"
        }}
      >
        <Typography.Title level={4} style={{ marginBottom: '16px' }}>
          Test Description
        </Typography.Title>

        <Form
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Category"
            name="category"
            rules={[{ required: true, message: 'Please select a category!' }]}
          >
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              placeholder="Select a category"
              allowClear
            >
              {categories.map(category => (
                <Option key={category.id} value={category.trainingCategoryId}>
                  {category.trainingCategoryName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Skill Tags"
            name="skillTags"
            rules={[{ required: true, message: 'Please select skill tags!' }]}
          >
            <Select
              mode="multiple"
              value={selectedSkillTags}
              onChange={handleSkillTagsChange}
              placeholder="Select skill tags"
              tagRender={(props) => (
                <Tag color="blue" {...props}>
                  {props.value}
                </Tag>
              )}
            >
              {skillTags.map(tag => (
                <Option key={tag.id} value={tag.tags.toLowerCase()}>
                  {tag.tags.toLowerCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Difficulty Level"
            name="difficultyLevel"
          >
            <Select
              value={selectedDifficultyLevel}
              onChange={handleDifficultyLevelChange}
              placeholder="Select difficulty level"
              allowClear
            >
              {difficultyLevels.map(level => (
                <Option key={level.id} value={level.questionDifficultyID}>
                  {level.description.toLowerCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Space style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="default" style={{ backgroundColor: '#f0f0f0' }}>
                Close
              </Button>
              <Button type="primary" htmlType="submit">
                Start
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      <Modal
        title="Insufficient Questions"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="OK"
      >
        <p>{modalContent}</p>
      </Modal>
    </>
  );
};

export default TestDescription;
