// passwordUtils.js

export const validateMobileNumber = (mobileNumber) => {
  const regex = /^\d{1,3}-\d{6,14}$/; // Example format: +123-456789
  return regex.test(mobileNumber);
};

export const validatePassword = (password) => {
  // Password must be at least 8 characters long and contain at least one special character
  const regex = /^(?=.*[@_#*%&]).{8,}$/;
  return regex.test(password);
};

// dateUtils.js

/**
 * Get the start and end dates of a financial year given a year range.
 * @param {string} selectedYear - The year range in 'YYYY-YYYY' format.
 * @returns {object} - An object with 'fromdate' and 'todate' properties.
 * 
 */
export function getFinancialYearDates(selectedYear) {
  if (!selectedYear) {
    console.error("Selected year is not defined or invalid");
    return { fromdate: null, todate: null };
  }

  const years = selectedYear.split('-');

  if (years.length !== 2) {
    console.error("Selected year format is incorrect");
    return { fromdate: null, todate: null };
  }

  const startYear = parseInt(years[0], 10);
  const endYear = parseInt(years[1], 10);

  if (isNaN(startYear) || isNaN(endYear)) {
    console.error("Years are not valid numbers");
    return { fromdate: null, todate: null };
  }

  const fromdate = `${startYear}-01-01`;
  const todate = `${endYear}-12-31`;

  return { fromdate, todate };
}

// utils/formatDuration.js
// utils/formatDuration.js
export const formatDuration = (duration) => {
  const totalMinutes = parseInt(duration, 10);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  let formattedDuration = '';

  if (hours > 0) {
      formattedDuration += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }

  if (minutes > 0) {
      if (formattedDuration) {
          formattedDuration += ' '; // Space between hours and minutes
      }
      formattedDuration += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }

  return formattedDuration || '0 minutes'; // Default to '0 minutes' if duration is 0
};


// You can add more utility functions related to passwords or other validation here if needed
