import React, { useState } from "react";
import {
    Modal,
    Box,
    Paper,
    Typography,
    IconButton,
    TextField,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    List,
    ListItem,
    ListItemIcon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Person from "@mui/icons-material/Person";
import People from "@mui/icons-material/People";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EmailModal = ({ passwordM, handleChangePassword }) => {
    const [position, setPosition] = useState({ x:260, y:0});
    const [dragging, setDragging] = useState(false);
    const [rel, setRel] = useState({  x:0, y: 30});
    const [selectedOption, setSelectedOption] = useState("Individual");
    const [editorData, setEditorData] = useState("");



    const handleMouseDown = (e) => {
        if (e.button !== 0) return;
        const pos = e.target.getBoundingClientRect();
        setDragging(true);
        setRel({
            x: e.pageX - pos.left,
            y: e.pageY - pos.top,
        });
        e.stopPropagation();
        e.preventDefault();
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!dragging) return;
        setPosition({
            x: e.pageX - rel.x,
            y: e.pageY - rel.y,
        });
        e.stopPropagation();
        e.preventDefault();
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        
        <Modal
            open={passwordM}
            onClose={handleChangePassword}
            aria-labelledby="draggable-modal-title"
            aria-describedby="draggable-modal-description"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "none",
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: `${position.y}px`,
                    cursor: dragging ? "grabbing" : "grab",
                    overflow: "auto",
                    left: {
                        xs: "10px", // 100% width on extra-small screens (mobile)
                        sm: `${position.x}px`, // 60% width on small screens (tablets) and up
                    },
                }}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
            >
                <Paper
                    sx={{
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        overflow: "auto",
                        height: "80vh",
                        width: "90vw",
                        maxWidth: 800,
                    }}
                >
                    <Box
                        id="draggable-modal-title"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            p: 1,
                            borderBottom: "1px solid #ccc",
                            cursor: "move",
                            width: {
                                xs: "94%", // 100% width on extra-small screens (mobile)
                                sm: "98%", // 60% width on small screens (tablets) and up
                            },
                        }}
                        onMouseDown={handleMouseDown}
                    >
                        <Typography variant="h6" style={{fontSize:"x-large"}}>Share Meeting Details</Typography>
                        <IconButton onClick={handleChangePassword}>
                            <CloseIcon style={{fontSize:"x-large"}}/>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: {
                                xs: "90%", // 100% width on extra-small screens (mobile)
                                sm: "60%", // 60% width on small screens (tablets) and up
                            },
                            flexDirection: {
                                xs: "column", // Stack vertically on extra-small screens (mobile)
                                sm: "row", // Align horizontally on small screens (tablets) and up
                            },
                        }}
                    >
                        <Typography id="draggable-modal-description" style={{ fontSize: "16px" }}>Mail to</Typography>
                        <RadioGroup
                            aria-label="Mail option"
                            name="mailOption"
                            value={selectedOption}
                            onChange={handleOptionChange}
                            sx={{ display: "flex", flexDirection: "row", }}
                        >
                            <List
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "0.5rem",
                                    minWidth: 240,

                                }}
                            >
                                {["Individual", "Team"].map((item, index) => (
                                    <ListItem
                                        key={item}
                                        sx={{ boxShadow: "sm", flex: 1, display: "flex", alignItems: "center", boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: "10px", border: selectedOption === item ? "1px solid blue" : "none", }}
                                    >
                                        <ListItemIcon>
                                            {[<Person style={{ fontSize: "x-large" }} />, <People style={{ fontSize: "x-large" }} />][index]}
                                        </ListItemIcon>
                                        <FormControlLabel
                                            value={item}
                                            control={<Radio />}
                                            label={item}
                                            sx={{ flexGrow: 1, flexDirection: "row-reverse", fontSize: "medium" }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </RadioGroup>
                    </Box>
                    {selectedOption === "Individual" && (
                        <Box sx={{ p: 1, }}>
                            <TextField
                                label="Enter email for Individual"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    style: { fontSize: '15px' }, // You can use this as an alternative
                                  }}InputProps={{
                                    style: {
                                      fontSize: "15px",
                                    },
              
                                  }}
                            />
                        </Box>
                    )}
                    <Box sx={{ p: 1 }}>
                        <TextField label="Subject" variant="outlined" fullWidth 
                        InputLabelProps={{
                            style: { fontSize: '15px' }, // You can use this as an alternative
                          }}InputProps={{
                            style: {
                              fontSize: "15px",
                            },
      
                          }}/>
                    </Box>
                    <Box sx={{ p: 1, flex: 1, overflow: "auto", maxHeight: "60vh" }}>
                        {/* <Typography variant="h6">Additional Details</Typography> */}
                        <CKEditor
                            editor={ClassicEditor}
                            data={editorData}
                            onChange={(event, editor) => {
                                setEditorData(editor.getData());
                            }}
                            config={{
                                toolbar: {
                                    items: [
                                        "heading",
                                        "|",
                                        "bold",
                                        "italic",
                                        "link",
                                        "|",
                                        "bulletedList",
                                        "numberedList",
                                        "blockQuote",
                                        "|",
                                        "insertTable",
                                        "mediaEmbed",
                                        "|",
                                        "undo",
                                        "redo",
                                        "|",
                                        "alignment",
                                        "fontColor",
                                        "fontBackgroundColor",
                                        "|",
                                        "horizontalLine",
                                        "codeBlock",
                                        "code",
                                        "|",
                                        "specialCharacters",
                                        "findAndReplace",
                                    ],
                                },
                                image: {
                                    toolbar: [
                                        "imageTextAlternative",
                                        "imageStyle:full",
                                        "imageStyle:side",
                                        "imageStyle:alignLeft",
                                        "imageStyle:alignRight",
                                    ],
                                },
                                language: "en",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            p: 1,
                            borderTop: "1px solid #ccc",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleChangePassword}
                            sx={{ mr: 1 }}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary">
                            Share
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
};

export default EmailModal;
