import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./fromcom.css";
import { NavLink } from "react-router-dom";
import Breadcrumb from "../commonComponent/Breadcrumbs";
const drawerWidth = "50%";

const toolbarStyles = {
  backgroundColor: "#1976d2",
  position: "relative",
  color: "white",
  fontSize: "20px",

  display: "flex",
  position: "relative",
  justifyContent: "space-between",

  // Ensure items are centered vertically
};

export default function AddPositionSidebar() {
  const [leftItems, setLeftItems] = useState([
    "Accountant",
    "Hr Manager",
    "Developer",
    "Project Manager",
    "Content Writer",
    "PD Co-ordinator",
  ]);
  const [rightItems, setRightItems] = useState([]);

  const handleAdd = (item) => {
    setLeftItems(leftItems.filter((i) => i !== item));
    setRightItems([...rightItems, item]);
  };

  const handleRemove = (item) => {
    setRightItems(rightItems.filter((i) => i !== item));
    setLeftItems([...leftItems, item]);
  };

  const handleAddMore = () => {
    const newItem = `Position ${leftItems.length + rightItems.length + 1}`;
    setLeftItems([...leftItems, newItem]);
  };

  const handleSave = () => {
    // Implement save functionality here
    alert("Saved!");
  };

  const handleBackToHome = () => {
    // Implement back to home functionality here
    //alert("Back to Home");
  };
  const paths = [
    { title: 'Home', url: '/' },
    { title: 'Dashboard', url: '/dashboard' },
    { title: 'Organizational Function', url: '/function-panel' },
    { title: 'Job Position', url: '/funcation-panel/add-position-sidebar' }
  ];
  return (
    <>
      <Box>
        <CssBaseline />
        {/* <Box
          sx={{
            backgroundColor: "#fff",
            color: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            // position: "-moz-initial",
            width: "100%",
            zIndex: 1201, // Ensures this box is above AppBar
          }}
        >
          <Typography variant="h6" noWrap component="div">
          Function : Software 
          </Typography>
          <Button
            variant="contained"
            onClick={handleBackToHome}
            style={{ backgroundColor: "#rgb(66, 139, 202)" }}
          >
            Back to Home
          </Button>
        </Box> */}
        <Breadcrumb paths={paths}/>
        <Box style={{display: 'flex', flexDirection: 'row'}}>
          <Drawer
            style={{ position: "relative" }}
            sx={{
              width: drawerWidth,

              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar style={toolbarStyles}>
              All Position
              <NavLink to="/jobposition-ant">
              <Button
                variant="contained"
                // onClick={handleAddMore}
                style={{ backgroundColor: "rgb(66, 139, 202)" }}
              >
                Add More
              </Button>
              </NavLink>
            </Toolbar>
            <Divider />
            <List>
              {leftItems.map((text) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton onClick={() => handleAdd(text)}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Drawer
            style={{ position: "relative" }}
            sx={{
              width: drawerWidth,

              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar style={toolbarStyles}>
            Function Position
              <Button
                variant="contained"
                onClick={handleBackToHome}
                style={{ backgroundColor: "rgb(66, 139, 202)" }}
              >
               Save
              </Button>
            </Toolbar>
            <Divider />
            <List>
            {rightItems.map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => handleRemove(text)}>
                <ListItemIcon>
                  <RemoveIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
            </List>
          </Drawer>
        </Box>
      </Box>
    </>
  );
}
