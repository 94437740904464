import React, { useState, useEffect } from 'react';
import { useFeedback } from '../../Context/FeedbackContext';
import Loader from '../../pages/Loader';
import axios from 'axios';
import { useConfig } from '../../ConfigContext';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const FacultyFeedback = ({ onPrevious, onSubmit }) => {
  const { 
    facultyFeedback, 
    setFacultyFeedback, 
    SingleSessionData, 
    selectedSessionId ,
    loading , 
    setLoading , 
    selectedSessionContent, 
    FeedbackQuestions, 
    setFeedback, 
    feedback ,
    setisReverse,
    setisVisible,
    setCurrentForm,
    setisFeedbackSubmitted
  } = useFeedback();
  const [feedbackData, setFeedbackData] = useState([]);
  const [completedFeedback, setCompletedFeedback] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const facultyarray = SingleSessionData?.sessionFaculties;
  const config = useConfig()
  const { id } = useParams()
  const user = JSON.parse(localStorage.getItem('user'));


  useEffect(() => {
    if (facultyFeedback) {
      const initialFeedbackData = facultyFeedback.Faculty_Feedback.map((item) => ({
        ratings: FeedbackQuestions.map(() => item.rating),
        comments: FeedbackQuestions.map(() => item.comment),
        showCommentBox: FeedbackQuestions.map((_, index) => item.rating <= 2 && index === 0),
      }));
      setFeedbackData(initialFeedbackData);
      setCompletedFeedback(Array(facultyFeedback.Faculty_Feedback.length).fill(false));
    } else {
      const initialFeedbackData = facultyarray.map(() => ({
        ratings: FeedbackQuestions.map(() => 0),
        comments: FeedbackQuestions.map(() => ''),
        showCommentBox: FeedbackQuestions.map(() => false),
      }));
      setFeedbackData(initialFeedbackData);
      setCompletedFeedback(Array(facultyarray.length).fill(false));
    }
  }, [facultyFeedback, facultyarray, FeedbackQuestions]);

  const handleRating = (index, questionIndex, rating) => {
    const newFeedbackData = [...feedbackData];
    newFeedbackData[index].ratings[questionIndex] = rating;
    newFeedbackData[index].showCommentBox[questionIndex] = rating <= 2;
    setFeedbackData(newFeedbackData);
  };

  const handleCommentChange = (index, questionIndex, event) => {
    const newFeedbackData = [...feedbackData];
    newFeedbackData[index].comments[questionIndex] = event.target.value;
    setFeedbackData(newFeedbackData);
  };

  const canProceed = () => {
    const currentItemFeedback = feedbackData[currentItemIndex];
    if (!currentItemFeedback) return false;

    for (let i = 0; i < FeedbackQuestions.length; i++) {
      if (currentItemFeedback.ratings[i] <= 2 && currentItemFeedback.comments[i].trim() === '') {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    console.log("Updated feedback:", feedback);
  }, [feedback]);

  const handleNextItem = () => {
    const currentItemFeedback = feedbackData[currentItemIndex];

    const newFeedbackItem = {
      contentId: null,
      facultyId: currentItem.facultyid || null,
      questions: FeedbackQuestions.map((question, questionIndex) => ({
        questionId: question.questionId,
        questionType: question.questionType.toString(),
        answerId: question.ratingAnswers[0]?.answerId || null,
        answerDescription: currentItemFeedback.comments[questionIndex] || null,
        rating: currentItemFeedback.ratings[questionIndex].toString(),
        isFacultyQuestion: 1,
      }))
    };

    const updatedFeedback = [...feedback];
    const existingIndex = updatedFeedback.findIndex(item =>
      item.facultyId === newFeedbackItem.facultyId
    );

    if (existingIndex !== -1) {
      updatedFeedback[existingIndex] = newFeedbackItem;
    } else {
      updatedFeedback.push(newFeedbackItem);
    }

    setFeedback(updatedFeedback);

    const updatedCompletedFeedback = [...completedFeedback];
    updatedCompletedFeedback[currentItemIndex] = true;
    setCompletedFeedback(updatedCompletedFeedback);

    if (currentItemIndex < facultyarray.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1);
    } else {
      postFeedback(updatedFeedback)
      console.log("merge", updatedFeedback);
      console.log("feed", feedback);
      onSubmit(updatedFeedback);
    }
  };

  const postFeedback = async (feedback)=>{

    if(id || feedback){

      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };
      
      const params = {
        trainingid: id,
        sessionid: selectedSessionId,
        loginagencyid: user?.userdetails?.agencyid,
      };

      try {
        const { data } = await axios.post(`${config?.REACT_APP_API_URL}/SaveContentFeedback`, 
        { feedback : feedback }, 
        { headers: headers , params: params });
  
        console.log('Feedback Posted Succesfully', data);
        if(SingleSessionData?.completiontype?.id === 1){
          await CompleteSessionHandler()
          toast.success('Session Completed Succesfully');
        }
        else{
          toast.success('Feedback Submitted Succesfully.');
        }
        await checkFeedbackStatus()
        setLoading(false);
        setFeedback([])    
      } catch (error) {
        console.error('Error posting session Comments:', error);
        toast.error('Failed to post feedback. Please try again.');
      } finally {
        setLoading(false);
      }      
    }
    else{
      console.error("Please provide Training id ");
    }
  }

  const CompleteSessionHandler = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };
  
      const params = {
        trainingid: id,
        Sessionid: selectedSessionId,
        Participantid: user?.userdetails?.agencyid,
        timeonsession:null,
        branchid: config?.REACT_APP_ID,
        status: 1,
      };
  
      // Construct the URL with parameters
      const url = `${config?.REACT_APP_API_URL}/Update_Session_Status?` +
        `Participantid=${encodeURIComponent(params.Participantid)}&` +
        `trainingid=${encodeURIComponent(params.trainingid)}&` +
        `Sessionid=${encodeURIComponent(params.Sessionid)}&` +
        `timeonsession=${encodeURIComponent(params.timeonsession)}&` +
        `branchid=${encodeURIComponent(params.branchid)}&` +
        `status=${encodeURIComponent(params.status)}`;
  
      try {
        const { data } = await axios.post(
          url, // Use the constructed URL with parameters
          null, // Pass null as the second argument since data is passed in URL query string
          { headers: headers }
        );
  
        console.log(data);
        setLoading(false);
      } catch (error) {
        console.error('Error Completing session', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('Please provide Training id');
    }
  };
  
  const checkFeedbackStatus = async () => {
    if (id) {
      const headers = {
        Accept: 'application/json, text/plain',
        APIKey: config?.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      };

      try {

        const { data } = await axios.get(`${config?.REACT_APP_API_URL}/CHECK_SESSION_FEEBDACK`, {
            headers: headers,
            params: {
                userid:user?.userdetails?.agencyid,
                sessionid:selectedSessionId, 
                trainingid:id 
            }
        });
        setisFeedbackSubmitted(data)
        console.log('CHECK_SESSION_FEEBDACK', data);
        setLoading(false);
      } catch (error) {
        console.log("error" , { sessionid:selectedSessionId  , trainingid:id })
        console.log('Error fetching sessions content', error);
        setLoading(false);
      }
    } else {
      console.log('No id provided');
      setLoading(false);      
    }
  };

  const handlePreviousItem = () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    } else {
      setisReverse(true)
      onPrevious();
    }
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const currentItem = facultyarray[currentItemIndex];

  console.log("first" , currentItem)

  if (!currentItem || loading)  {
    return <Loader />;
  }

  return (
    <>
      <div className="space-y-7">
        <div className="flex items-center gap-3">
          <div className='rounded-full h-[45px] w-[45px] border-2 overflow-hidden'>
            <img
              className="h-full w-full object-cover object-top "
              src={`${config?.LITTERA_CDN_BASE_URL}/${config?.LITTERA_CDN_PROFILE_PICK_PATH}/${currentItem?.facultuimg}`}
              alt=""
            />
          </div>        
          <h4>{currentItem?.facultyname}</h4>
        </div>
          <div className='space-y-7 overflow-y-auto '>
            {FeedbackQuestions.map((question, questionIndex) => (
              <div key={questionIndex}>
                <label className="block text-sm font-medium mb-4 text-black">
                  {stripHtmlTags(question.questionText)}
                </label>
                <div className="flex space-x-3">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <button
                      key={value}
                      className={`focus:outline-none ${
                        feedbackData[currentItemIndex]?.ratings[questionIndex] >= value ? 'text-yellow-500' : 'text-gray-400'
                      }`}
                      onClick={() => handleRating(currentItemIndex, questionIndex, value)}
                    >
                      ★
                    </button>
                  ))}
              </div>
              {feedbackData[currentItemIndex]?.showCommentBox[questionIndex] && (
                <input
                  type="text"
                  className="mt-2 w-full border rounded px-3 py-2"
                  placeholder={`Please provide feedback for ${currentItem?.facultyname}`}
                  value={feedbackData[currentItemIndex]?.comments[questionIndex]}
                  onChange={(event) => handleCommentChange(currentItemIndex, questionIndex, event)}
                />
              )}
            </div>
        ))}
        </div>
      </div>

      <div className="mt-10 flex justify-between">
        <button
          className={`bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-6 rounded focus:outline-none`}
          onClick={handlePreviousItem}
        >
          Previous
        </button>
        <button
          className={`bg-[#428BCA] hover:bg-[#428BCA] text-white py-3 px-6 rounded focus:outline-none ${
            !canProceed() ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleNextItem}
          disabled={!canProceed()}
        >
          {currentItemIndex === facultyarray.length - 1 ? 'Submit' : 'Next'}
        </button>
      </div>
    </>
  );
};

export default FacultyFeedback;