import React, { useState, useEffect } from 'react';
import { Box, IconButton, Button, Paper } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for unique file names

const FileUploadAndSave = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(''); // State to hold the preview URL

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const filePreviewUrl = URL.createObjectURL(file);
      setSelectedFile(file);
      setPreviewUrl(filePreviewUrl);
    }
  };

  // Handle file deletion
  const handleDeleteFile = () => {
    if (selectedFile) {
      URL.revokeObjectURL(previewUrl); // Clean up the object URL
      setSelectedFile(null);
      setPreviewUrl('');
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!selectedFile) {
      alert('No file selected');
      return;
    }

    // Generate a unique file name using UUID
    const uniqueFileName = `${uuidv4()}.png`;

    const formData = new FormData();
    formData.append('files', selectedFile, uniqueFileName);

    try {
      const uploadUrl = 'http://p9:8888/Upload/UploadFile?Foldername=training_upload';

      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          // 'Authorization': `Bearer ${apiKey}` // Example API key as Bearer token
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log('Upload successful:', responseData);

      // Clear selected file after successful upload
      setSelectedFile(null);
      setPreviewUrl('');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(`Error: ${error.message}`);
    }
  };

  // Cleanup object URL when component unmounts or file changes
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          minHeight: 110,
          border: '2px dashed #0056b3',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden', // Ensure the image doesn't overflow the container
        }}
        component="label"
      >
        <input
          type="file"
          id="file-upload"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <label htmlFor="file-upload">
          <IconButton component="span" color="primary" size="large" aria-label="Upload file">
            <UploadIcon />
          </IconButton>
        </label>

        {/* Show the preview image within the Paper container */}
        {previewUrl && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1,
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 1,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              }}
              onClick={handleDeleteFile}
              aria-label="Delete file"
            >
              <DeleteIcon />
            </IconButton>
            <img
              src={previewUrl} // Use previewUrl to display the preview image
              alt="Selected file preview"
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                borderRadius: '4px',
              }}
            />
          </Box>
        )}
      </Paper>
      <Button variant="contained" onClick={handleUpload} disabled={!selectedFile}>Upload</Button>
    </Box>
  );
};

export default FileUploadAndSave;
