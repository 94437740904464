// import React, { useEffect, useState } from 'react';
// import { Button, Modal, Spin, Typography, Form, Input, Alert } from 'antd';
// import { CloseOutlined, FullscreenExitOutlined, FullscreenOutlined, PlayCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
// import axios from 'axios';

// const { Title, Text } = Typography;

// const StartTest = () => {
//     const [clientData, setClientData] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [modalVisible, setModalVisible] = useState(false);
//     const [examStarted, setExamStarted] = useState(false);
//     const [examCompleted, setExamCompleted] = useState(false);

//     useEffect(() => {
//         const fetchClientData = async () => {
//             try {
//                 const response = await axios.get('http://p9:8888/TrainingApi/GET_CLIENT_PROFILE_DATA', {
//                     params: {
//                         Domain: 'YOJNAACADEMY_RCVP',
//                         isonline: 0,
//                         agencytypeid: '00001',
//                         Clientid: 'dff7c661-5b84-4a7e-8250-31c420dd9fcd',
//                         APIKEY: 'EQZBGPECQEIUUDQTBXCHBGEQZBGPECQEIUUDQTBXCHBGEQZBGPECQEIUUDQTBXCHBGECQEIUUDQTBXCHBGECQEIUUDQTBXCHBGEQZBGP'
//                     }
//                 });
//                 if (response.data[0].status.toUpperCase() === 'SUCCESS') {
//                     setClientData(response.data[0]);
//                 } else {
//                     alert(response.data[0].msg);
//                 }
//             } catch (error) {
//                 alert('Error fetching client data');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchClientData();
//     }, []);

//     const handleStartExam = () => {
//         setExamStarted(true);
//         setModalVisible(true);
//     };

//     const handleCompleteExam = () => {
//         setExamCompleted(true);
//         setExamStarted(false);
//         setModalVisible(false);
//     };

//     const handleCancel = () => {
//         setModalVisible(false);
//     };

//     return (
//         <div style={{ padding: '20px' }}>
//             {loading ? (
//                 <div className="text-center">
//                     <Spin size="large" />
//                 </div>
//             ) : (
//                 <div>
//                     {clientData && (
//                         <div className="client-info">
//                             <img
//                                 src={`../${clientData.logo}`}
//                                 alt="Client Logo"
//                                 style={{ maxWidth: '100px' }}
//                             />
//                             <div>
//                                 <Title level={4}>{clientData.clientname}</Title>
//                                 <Text>{clientData.address}</Text>
//                                 <br />
//                                 <Text>{clientData.city} {clientData.Pinno}</Text>
//                                 <br />
//                                 <Text>{clientData.contactno}</Text>
//                             </div>
//                         </div>
//                     )}

//                     <div className="text-center">
//                         <Button
//                             type="primary"
//                             icon={<PlayCircleOutlined />}
//                             onClick={handleStartExam}
//                         >
//                             Start
//                         </Button>
//                     </div>

//                     <Modal
//                         title="Exam"
//                         visible={modalVisible}
//                         onCancel={handleCancel}
//                         footer={[
//                             <Button key="fullscreen" type="primary" icon={<FullscreenOutlined />} onClick={() => alert('Fullscreen Mode')}>
//                                 Go To Full Screen
//                             </Button>,
//                             <Button key="prev" icon={<LeftOutlined />}>
//                                 Previous
//                             </Button>,
//                             <Button key="next" type="primary" icon={<RightOutlined />}>
//                                 Next
//                             </Button>
//                         ]}
//                     >
//                         <div className="modal-body">
//                             {examStarted ? (
//                                 <div>
//                                     <Title level={4}>Test Name: <Text strong>{clientData.testName}</Text></Title>
//                                     <Text>Don't try to change tab or exit full-screen between the exam, exam will be terminated.</Text>
//                                     <br />
//                                     <Button type="primary" onClick={handleCompleteExam}>
//                                         Complete Exam
//                                     </Button>
//                                 </div>
//                             ) : (
//                                 <Alert
//                                     message="Click on start to begin your exam!"
//                                     type="info"
//                                     showIcon
//                                 />
//                             )}
//                         </div>
//                     </Modal>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default StartTest;

import { Box, Button } from '@mui/material'
import { Typography } from 'antd'
import React from 'react'
import { NavLink } from 'react-router-dom'

const StartTest = () => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundColor: "#f9f9f9",

        }}>
            <Box sx={{ textAlign: "center", mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={require("../../../src/assest/logo_pra1.png")}
                    alt='logo'
                    style={{ width: '66px' }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '2rem', // Adjust the font size as needed
                    }}
                >
                    Pratham Softcon Private Limited
                </Typography>
                <Typography variant="body2" style={{ fontSize: 'medium' }}>
                    9 Amltash II, Chuna Bhatti Kolar Road <br />
                    Bhopal 462016
                </Typography>
            </Box>
            <Box sx={{
                width: "88%",
                backgroundColor: "#e0f7fa",
                p: 4,
                borderRadius: 2,
                boxShadow: 2,
                textAlign: 'center',
            }}>
                <Typography variant="h6" color="#31708f" style={{ fontSize: "medium" }}> Click to start to begin your exam !</Typography>
                <NavLink to="/online-test-page">
                    <Button variant="contained" color="primary" size="large">
                        Start
                    </Button>
                </NavLink>
            </Box>

        </Box>
    )
}

export default StartTest

