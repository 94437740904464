import moment from 'moment'
import React from 'react'

const CustomDayCell = ({ value , children}) => {
  const dateStr = moment(value).format('YYYY-MM-DD')
  return (
    <div className='rbc-day-bg' date-date={dateStr}>
        {children}
    </div>
  )
}

export default CustomDayCell