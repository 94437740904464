import React from "react";
import "./about.css";
import ThreeAccordions from "./Accordation";
const AboutUs = () => {
  return (
    <>
      <div id="aboutus" className="aboutus-sections">
        <div className="about-txt">
          <section className="bg-white dark:bg-gray-900">
            <div
              className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6"
              style={{ display: "flex", justifyContent: "center" ,marginTop:"20px"}}
            >
              <div className="max-w-screen-lg text-gray-500 sm:text-lg dark:text-gray-400">
                <h2
                  className="mb-4  tracking-tight font-bold text-gray-900 dark:text-white"
                  style={{ textAlign: "center" }}
                >
                  ABOUT US
                </h2>
                <p className="mb-4 font-light" style={{ textAlign: "center",fontSize:"15px" }}>
                  Littera Learning platform specifically designed to provide you
                  with a unique educational experience, whether in the classroom
                  or virtual, to acquire the necessary skills to increase your
                  success in conducting Training.
                </p>
              </div>
            </div>
          </section>
        </div>

        <div className="responsive-container-block bigContainer">
          <div className="responsive-container-block Container bottomContainer">
            <div className="ultimateImg">
              <img
                className="mainImg"
                src={require("../../assest/about2.jpg")}
              />
              {/* <div className="purpleBox">
                <p className="purpleText">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                  purus lectus viverra in semper nec pretium mus.
                </p>
                <img
                  className="stars"
                  src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg"
                />
              </div> */}
            </div>
            <div className="allText bottomText">
              <p className="text-blk headingText">About Me</p>
              <p className="text-blk subHeadingText">WELCOME TO LITTERA</p>
              <p className="text-blk description">
                Littera from where the knowledge sprouts
              </p>
              <ThreeAccordions />
              {/* <a className="explore">View More</a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
